import React from "react";
import { useEffect, useState } from "react";
import $ from "jquery";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

const TrustedByBrands = () => {
  return (
    <div className="container-fluid trusted-by-brands-container">
      <div className="trusted-by-brands">
        <div className="row justify-content-md-center">
          <WebVersion />
          <MobileVersion />
        </div>
      </div>
    </div>
  );
};
const { title } = TextConstants.TRUSTED_BY_BRANDS;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div className="row justify-content-md-center">
        <div
          className="col-lg-10 col-12"
          id="textSection"
          style={{ margin: 0 }}
        >
          <h2
            className="font lora f-32 ws-6 w-400 black-2b justify-in-center"
            style={{ marginBottom: 40 }}
          >
            {title}
          </h2>
        </div>
      </div>
      <div className="row justify-content-md-center">
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index < 5 && (
              <div
                className="col-2"
                style={{ textAlign: "center", marginBottom: 75 }}
                id="textSection"
              >
                <img
                  width="162px"
                  height="62px"
                  style={{ objectFit: "contain" }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index > 5 &&
            index < 10 && (
              <div
                className="col-3"
                style={{ textAlign: "center", marginBottom: 75 }}
                id="textSection"
              >
                <img
                  width="162px"
                  height="62px"
                  style={{ objectFit: "contain" }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index > 9 && (
              <div
                className="col-2"
                style={{ textAlign: "center" }}
                id="textSection"
              >
                <img
                  width="162px"
                  height="62px"
                  style={{ objectFit: "contain" }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="col-lg-10 col-12" id="textSection">
        <h2
          className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
          style={{ marginBottom: 20 }}
        >
          {title}
        </h2>
      </div>
      <div className="row justify-content-md-center justify-in-center">
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index < 9 && (
              <div
                className="col-4"
                style={{ textAlign: "center", marginBottom: 30 }}
                id="textSection"
              >
                <img
                  style={{ objectFit: "contain", width: "100%", height: 43 }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index > 8 &&
            index < 11 && (
              <div
                className="col-6"
                style={{ textAlign: "center", marginBottom: 30 }}
                id="textSection"
              >
                <img
                  style={{ objectFit: "contain", width: "100%", height: 43 }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
        {FileConstants.trusted_by_top_brands.map(
          (logo, index) =>
            index > 10 && (
              <div
                className="col-4"
                style={{ textAlign: "center", marginBottom: 30 }}
                id="textSection"
              >
                <img
                  style={{ objectFit: "contain", width: "100%", height: 43 }}
                  src={logo}
                  alt={index}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default TrustedByBrands;
