import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import TextConstants from "../../../TextConstants";

const CURRENT_YEAR = new Date().getFullYear();

function Footer() {
  return (
    <div>
      <FooterLine />
    </div>
  );
}
function FooterLine() {
  return (
    <React.Fragment>
      <WebVersion />
      <MobileVersion />
    </React.Fragment>
  );
}

function SocialIcons() {
  return (
    <span className="socialIcons col " style={{ padding: 0 }}>
      <div className="iconHolder justify-space-between">
        <a href="https://www.instagram.com/all3d.ai/" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/insta.svg"
            alt="Instagram"
          />
        </a>
        <a href="https://twitter.com/all3d_ai" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/twitter.svg"
            alt="Twitter"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/all3d-ai/about/"
          target="_blank"
        >
          <img
            className="social-icons"
            src="/landingPage/linkedin.svg"
            alt="LinkedIn"
          />
        </a>
        <a href="https://www.facebook.com/all3dai/" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/fb.svg"
            alt="Facebook"
          />
        </a>
      </div>
    </span>
  );
}

const {
  title,
  text,
  btn1_text,
  btn2_text,
  reserved_rights,
  company_name,
  investment_consent,
  consent_btn,
  subtitle,
  support,
  company,
} = TextConstants.FOOTER;

function WebVersion() {
  return (
    <div className="d-none d-lg-block">
      <div className="d-none d-lg-flex">
        <div
          className="col-6 footer-sec-1-pd left"
          style={{ background: "#C2A374" }}
        >
          <h3
            className="font graphik-regular f-24 w-600 white"
            style={{ marginBottom: 16 }}
          >
            {title}
          </h3>
          <p
            className="font graphik-regular f-16 w-400 white"
            style={{ marginBottom: 16 }}
          >
            {text}
          </p>
          <button
            type="button"
            className="btn get-started-btn-white font graphik-regular f-16 w-500 brown"
            onClick={() => {
              window.location.href = "https://app.all3d.ai/";
            }}
          >
            {btn1_text}
          </button>
        </div>
        <div
          className="col-6 justify-in-center  footer-sec-1-pd right"
          style={{ alignItems: "flex-start", background: "#23222D" }}
        >
          {/* <div className='col'>

        <div style={{display: 'grid', justifyContent: 'flex-end'}}>
          <p style={{marginBottom: 16}} className='font graphik-regular f-16 w-600 white'>{investment_consent}</p>
          <a  style={{marginBottom: 16}} href="https://republic.com/all3d" className='font graphik-regular f-14 w-400 brown' target='_blank'>{consent_btn}<ArrowRightOutlined/></a>

        </div>
      </div> */}
          <div className="col">
            <div style={{ display: "grid", justifyContent: "flex-end" }}>
              <button
                type="button"
                className="btn signup-btn font graphik-medium f-16 w-400 brown border-brown"
                onClick={() => {
                  window.location.href =
                    "https://app.all3d.ai/register_as_artist";
                }}
              >
                {btn2_text}
              </button>
            </div>
          </div>
          <div className="col">
            <div style={{ display: "grid", justifyContent: "flex-end" }}>
              <p
                style={{ marginBottom: 16 }}
                className="font graphik-regular f-16 w-600 white"
              >
                {subtitle[0]}
              </p>
              <a
                style={{ marginBottom: 16 }}
                href="/how-all3d-works"
                className="font graphik-regular f-14 w-400 white"
              >
                {support[0]}
              </a>
              <a
                style={{ marginBottom: 16 }}
                href="/contact-us"
                className="font graphik-regular f-14 w-400 white"
              >
                {support[1]}
              </a>
              <a
                style={{ marginBottom: 16 }}
                href="/faqs"
                className="font graphik-regular f-14 w-400 white"
              >
                {support[2]}
              </a>
              {/* <a  style={{marginBottom: 16}} href="/faqs" className='font graphik-regular f-14 w-400 white'> {support[3]}</a> */}
            </div>
          </div>
          <div className="col">
            <div style={{ display: "grid", justifyContent: "flex-end" }}>
              <p
                style={{ marginBottom: 16 }}
                className="font graphik-regular f-16 w-600 white"
              >
                {subtitle[1]}
              </p>
              <a
                style={{ marginBottom: 16 }}
                href="/about-us"
                className="font graphik-regular f-14 w-400 white"
              >
                {company[0]}
              </a>
              <a
                style={{ marginBottom: 16 }}
                href="/privacy"
                className="font graphik-regular f-14 w-400 white"
              >
                {company[1]}
              </a>
              <a
                style={{ marginBottom: 16 }}
                href="/terms"
                className="font graphik-regular f-14 w-400 white"
              >
                {company[2]}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-sec-2-pd justify-space-between">
        <div className="col-6" style={{ paddingLeft: 0 }}>
          <a href="https://www.instagram.com/all3d.ai/" target="_blank">
            <img
              className="social-icons big"
              src="/insta_grey.png"
              style={{ marginRight: 40 }}
              alt="Instagram"
            />
          </a>
          <a href="https://twitter.com/all3d_ai" target="_blank">
            <img
              className="social-icons big"
              src="/twitter_grey.png"
              style={{ marginRight: 40 }}
              alt="Twitter"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/all3d-ai/about/"
            target="_blank"
          >
            <img
              className="social-icons big"
              src="/in_grey.png"
              style={{ marginRight: 40 }}
              alt="LinkedIn"
            />
          </a>
          <a href="https://www.facebook.com/all3dai/" target="_blank">
            <img
              className="social-icons big"
              src="/fb_grey.png"
              style={{ marginRight: 40 }}
              alt="Facebook"
            />
          </a>
        </div>
        <div className="col-6 justify-in-end" style={{ paddingRight: 0 }}>
          <a
            href="https://apps.apple.com/app/all3d-scanning-app/id1513820542"
            target="_blank"
          >
            <img
              src="https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section7_logo.png"
              alt="App Store"
            />
          </a>
        </div>
      </div>

      <div className="footer-sec-3-pd justify-space-between">
        <div className="col-6" style={{ paddingLeft: 0 }}>
          <h5 className="font graphik-regular f-16 w-400 white">
            {company_name + CURRENT_YEAR + reserved_rights}
          </h5>
        </div>
        <div className="col-6 justify-in-end" style={{ paddingRight: 0 }}>
          <Link className="navbar-brand" to={"/"}>
            <img src="/new-all3d-logo.png" style={{objectFit: 'contain'}} width="86px" height="30px" alt="ALL3D" />
          </Link>
        </div>
      </div>
    </div>
  );
}

function MobileVersion() {
  return (
    <div className="footer-line d-lg-none">
      <div
        className="row justify-space-between"
        style={{ alignItems: "center" }}
      >
        <div className="col-lg-3 col-12">
          <Link className="navbar-brand" to={"/"}>
            <img src="/All3DLogo.svg" width="54px" height="15px" alt="ALL3D" />
          </Link>
          <p
            style={{ marginTop: 12, marginBottom: 14 }}
            className="font graphik-regular f-14 w-300 white"
          >
            {company_name + CURRENT_YEAR + reserved_rights}
          </p>
          <SocialIcons />
        </div>
        <div className="col-lg-5">
          {/* <div className='justify-in-center direction-flex col' style={{alignItems: 'center',marginTop: 32}}>
        <p style={{marginBottom: 16}} className='font graphik-regular f-14 w-600 white'>{investment_consent}</p>
        <a  style={{marginBottom: 16}} href="https://republic.com/all3d" target="_blank" className='font graphik-regular f-14 w-300 brown'>{consent_btn}<ArrowRightOutlined/></a>
      </div> */}
          <div
            className="justify-in-center direction-flex col"
            style={{ alignItems: "center", marginTop: 32 }}
          >
            <p
              style={{ marginBottom: 16 }}
              className="font graphik-regular f-16 w-600 white"
            >
              {subtitle[0]}
            </p>
            <a
              style={{ marginBottom: 16 }}
              href="/contact-us"
              target="_blank"
              className="font graphik-regular f-14 w-300 white"
            >
              {support[0]}
            </a>
            <a
              style={{ marginBottom: 16 }}
              href="/contact-us"
              target="_blank"
              className="font graphik-regular f-14 w-300 white"
            >
              {support[1]}
            </a>
            <a
              style={{ marginBottom: 16 }}
              href="/faqs"
              target="_blank"
              className="font graphik-regular f-14 w-300 white"
            >
              {support[2]}
            </a>
            {/* <a  style={{marginBottom: 16}} href="/faqs" target="_blank" className='font graphik-regular f-14 w-300 white'>Return Policy</a> */}
          </div>
          <div
            className="justify-in-center direction-flex col"
            style={{ alignItems: "center", marginTop: 32 }}
          >
            <p
              style={{ marginBottom: 16 }}
              className="font graphik-regular f-16 w-600 white"
            >
              {subtitle[1]}
            </p>
            <a
              style={{ marginBottom: 16 }}
              href="/about-us"
              target="_blank"
              className="font graphik-regular f-14 w-300 white"
            >
              {company[0]}
            </a>
            <a
              style={{ marginBottom: 16 }}
              target="_blank"
              href="/privacy"
              className="font graphik-regular f-14 w-300 white"
            >
              {company[1]}
            </a>
            <a
              style={{ marginBottom: 16 }}
              target="_blank"
              href="/terms"
              className="font graphik-regular f-14 w-300 white"
            >
              {company[2]}
            </a>
            <a
              style={{ marginBottom: 16 }}
              target="_blank"
              href="/how-all3d-works"
              className="font graphik-regular f-14 w-300 white"
            >
              {company[3]}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
