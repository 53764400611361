import React from 'react';
import {ReactComponent as ARIcon} from '../../../ar.svg';
import ModelViewer from './modelViewer';
import {Animated} from "react-animated-css";
import { useEffect, useState } from 'react'
import $ from 'jquery';


function SectionTwo() {
	const [visible1,setVisible1] = useState(false);
	const [visible2,setVisible2] = useState(false);

	//useEffect(_ => {
		// window.addEventListener('scroll', () => {
		// 	var top = $(window).scrollTop() + $(window).height()
		// 	var classTop = $('#textSection').offset().top;
		// 	var isVisible = false;
		// 	if (classTop != undefined) {
		// 		isVisible = top > classTop
		// 		if (isVisible){
		// 			setVisible1(true);
		// 		} 
		// 	}
		// 	classTop = $('#frameSection').offset().top;
		// 	if (classTop != undefined) {
		// 		isVisible = top > classTop
		// 		if (isVisible){
		// 			setVisible2(true);
        //         } 
		// 	}
		// });    
	 // }, []);

	return (
		<div className='container-fluid'>
			<div className='section-2'>
				<div className='row justify-content-md-center'>
					<div className='col-lg-7 col-12 order-2 '  id="textSection">

						<div className='grey-bg'>
						<ModelViewer />
						</div>
						
						<div className='helper d-lg-none d-block m-3 text-center'>
						  * Click on the <ARIcon /> icon above to view in your Room
						</div>
					</div>
					<div className='col-lg-5 col-12 order-1 order-lg-2' id="frameSection">
					<div className='text-sec2-cta mid-res'>
						{/* <div > */}
							<div className='text-border-brake'></div>
							<div className='side-text'>
							We create the highest quality 3D models for any application, anywhere

							</div>
							<div className='helper d-lg-block d-none mt-40'>* Open this site on your mobile browser to view this model in your room</div>
						{/* </div> */}
					</div>

					</div>
				</div>

			</div>

		</div>
	);
}


export default SectionTwo;
