import React from "react";
import "./App.scss";
import LandingPage from "./components/landingPage/";
import ContactUs from "./components/contactUs/";
import Store from "./components/store";
import ProductAssets from "./components/productAssets";
import ComingSoon from "./components/comingSoon";
import Pricing from "./components/pricing";
import PrivacyAndTerms from "./components/privacyAndTerms";
import aboutus from "./components/aboutUs/aboutus.jsx";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router";
import SkylineBohoRenders from "./components/skylineBohoRenders";
import ProductPage from "./components/productPage";
import SpacePage from "./components/spacePage";
import SkylineDecoRenders from "./components/skylineDecoRenders";
import preppyRenders from "./components/preppyRenders";
import FarmhouseRenders from "./components/farmhouseRenders";
import SubscriptionPlans from "./components/SubscriptionPlans/SubscriptionPlans.jsx";
import UnitPrice from "./components/UnitPrice/UnitPrice.jsx";
import FAQs from "./components/FAQs";
import HowALL3DWorks from "./components/HowALL3DWorks/HowALL3DWorks";
import SceneDetails from "./components/SceneDetail";
import HighPointLandingPage from "./components/HighPointLandingPage";
import LifestylesFeed from "./components/LifestylesFeed/LifestylesFeed";
import Integrations from "./components/Integrations/Integrations"
import Amazon from "./components/Amazon/Amazon.jsx";
import ThreeDExpertTerms from "./components/privacyAndTerms/ThreeDExpertTerms.jsx"
import Termsandconditions from "./components/Termsandconditions/Termsandconditions.jsx"

function App() {
  const reload = () => window.location.reload();

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/store" component={Store} />
        <Route exact path="/store/:brand" component={Store} />
        <Route exact path="/product-assets/:id" component={ProductPage} />
        <Route exact path="/room-assets/:id" component={SpacePage} />
        <Route exact path="/scene-assets/:id" component={SceneDetails} />
        <Route exact path="/pricing" component={SubscriptionPlans} />
        <Route exact path="/faqs" component={FAQs} />
        <Route exact path="/unit-price" component={UnitPrice} />
        <Route exact path="/integrations" component = {Integrations} />
        <Route exact path="/amazon" component = {Amazon} />
        <Route exact path="/termsandconditions" component = {Termsandconditions} />
        <Route
          exact
          path={"/terms"}
          render={() => <PrivacyAndTerms tab="terms" />}
        />
        <Route
          exact
          path={"/privacy"}
          render={() => <PrivacyAndTerms tab="privacy" />}
        />
        <Route exact path="/about-us" component={aboutus} />
        <Route exact path="/blogs" component={ComingSoon} />
        <Route exact path="/how-all3d-works" component={HowALL3DWorks} />
        <Route
          exact
          path="/skyline/modernboho/images"
          component={SkylineBohoRenders}
        />
        <Route
          exact
          path="/skyline/decoglam/images"
          component={SkylineDecoRenders}
        />
        <Route
          exact
          path="/skyline/preppytradional/images"
          component={preppyRenders}
        />
        <Route
          exact
          path="/skyline/farmhouse/images"
          component={FarmhouseRenders}
        />
        <Route path="/sitemap.xml" onEnter={reload} />
        <Route
          exact
          path="/kayak-ar"
          component={() => {
            window.location.href =
              "https://d48f7equ64qjl.cloudfront.net/product_assets/usdz/5318.usdz";
            return null;
          }}
        />
        <Route
          exact
          path="/kayak-3d"
          component={() => {
            window.location.href =
              "https://app.all3d.ai/product_public_viewer/product_3d_model/5318";
            return null;
          }}
        />
        <Route
          exact
          path="/kayak-prerendered-360"
          component={() => {
            window.location.href =
              "https://app.all3d.ai/product_public_viewer/product_360/5251";
            return null;
          }}
        />

        <Route
          exact
          path="/high-point-landing-page"
          component={HighPointLandingPage}
        />

        <Route
          exact
          path="/feed"
          component={LifestylesFeed}
        />
        <Route
          exact
          path="/three-d-terms"
          component={ThreeDExpertTerms}
        />

        <Route path="/" component={LandingPage} />
      </Switch>
    </Router>
  );
}

export default App;
