import React from "react";
import { useEffect, useState } from "react";
import $ from "jquery";
import VisibilitySensor from "react-visibility-sensor";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <div className="container-fluid how-it-works-container">
      <div className="how-it-works">
        <div className="row justify-content-md-center">
          <div className="col-lg-10 col-12" id="textSection">
            <WebVersion />
            <MobileVersion />
          </div>
        </div>
      </div>
    </div>
  );
};

const { title, btn_text, error_msg } = TextConstants.HOW_IT_WORKS;
const WebVersion = () => {
  const [videoElement, setVideoElement] = useState(React.createRef());

  const onChangeVisibility = (isVisible) => {
    const node = videoElement.current;
    if (node) {
      if (isVisible == true) {
        node.play();
      } else {
        node.pause();
      }
    }
  };
  return (
    <div className="d-none d-lg-block">
      <h2
        className="font lora f-32 ws-6 w-400 black-2b justify-in-center"
        style={{ marginBottom: 40 }}
      >
        {title}
      </h2>
      <div className="justify-in-center mb-36">
        <VisibilitySensor onChange={onChangeVisibility}>
          <div className="scene-video">
            <div className="container-fluid">
              <div className="row no-gutters justify-content-center">
                <div className="col-12 text-center parent-video">
                  <iframe
                    src="https://www.youtube.com/embed/k6kZuZ2VS2o"
                    title="How Our Platform Works"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  {/* <video controls loop ref={videoElement} playsInline poster="https://d48f7equ64qjl.cloudfront.net/static_files/how_it_works/how-it-works-poster.png">
							<source  src="https://d48f7equ64qjl.cloudfront.net/static_files/how_it_works/how-it-works.mp4" type="video/mp4" />
							{error_msg}
						</video> */}
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
      <div className="justify-in-center">
        <Link
          to={{
            pathname:
              "https://all3d.ai/how-all3d-works?video_tutorial=Aab47RP56k8",
          }}
          target="_blank"
        >
          <button
            type="button"
            className="btn learn-more-btn font graphik-regular f-16 w-500 white"
          >
            {btn_text}
            <img className="ml-12" src="/landingPage/right-arrow.png" alt="#" />
          </button>
        </Link>
      </div>
    </div>
  );
};
const MobileVersion = () => {
  const [videoElement, setVideoElement] = useState(React.createRef());

  const onChangeVisibility = (isVisible) => {
    const node = videoElement.current;
    if (node) {
      if (isVisible == true) {
        node.play();
      } else {
        node.pause();
      }
    }
  };
  return (
    <div className="d-lg-none">
      <h2
        className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
        style={{ marginBottom: 12 }}
      >
        {title}
      </h2>
      <div className="justify-in-center mb-20">
        <VisibilitySensor onChange={onChangeVisibility}>
          <div className="scene-video">
            <div className="container-fluid">
              <div className="row no-gutters justify-content-center">
                <div className="col-12 text-center parent-video">
                  <iframe
                    src="https://www.youtube.com/embed/k6kZuZ2VS2o"
                    title="How Our Platform Works"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  {/* <video controls loop ref={videoElement} playsInline poster="https://d48f7equ64qjl.cloudfront.net/static_files/how_it_works/how-it-works-poster.png">
							<source  src="https://d48f7equ64qjl.cloudfront.net/static_files/how_it_works/how-it-works.mp4" type="video/mp4" />
							{error_msg}
						</video> */}
                </div>
              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
      <div className="justify-in-center">
        <Link
          to={{
            pathname:
              "https://all3d.ai/how-all3d-works?video_tutorial=Aab47RP56k8",
          }}
          target="_blank"
        >
          <button
            type="button"
            className="btn learn-more-btn font graphik-regular f-14 w-500 white"
          >
            {btn_text}
            <img className="ml-12" src="/landingPage/right-arrow.png" alt="#" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default HowItWorks;
