import React from "react";
import "./index.scss";
import TextConstants from "../../../TextConstants";

const ScanningApp = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};
const { title, text, error_msg } = TextConstants.SCANNING_APP;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block" style={{ width: "100%" }}>
      <div
        className="justify-space-between our-product-offering"
        style={{ marginTop: 120 }}
      >
        <div className="col-lg-6 col-12" style={{ paddingRight: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-16 w-400 grey-light">{text}</p>
          <a
            href="https://apps.apple.com/app/all3d-scanning-app/id1513820542"
            target="_blank"
          >
            <img
              src="https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section7_logo.png"
              alt="App Store"
            />
          </a>
        </div>
        <div
          className="col-lg-6 col-12 justify-in-center"
          style={{ flexDirection: "column", alignItems: "flex-end" }}
        >
          <img
            className="section-img phone"
            src="/body.png"
            alt="Phone Sleeve"
          />
          <video
            className="position-iphone-video"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section7.mp4"
              type="video/mp4"
            />
            {error_msg}
          </video>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="our-product-offering" style={{ marginTop: 48 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ marginBottom: 20 }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-14 w-400 grey-light">{text}</p>
          <a
            href="https://apps.apple.com/app/all3d-scanning-app/id1513820542"
            target="_blank"
          >
            <img
              src="https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section7_logo.png"
              alt="App Store"
            />
          </a>
        </div>
        <div
          className="col-12 justify-in-center"
          style={{ flexDirection: "column", alignItems: "center" }}
        >
          <img
            className="section-img phone"
            src="/body.png"
            alt="Phone Sleeve"
          />
          <video
            className="position-iphone-video"
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section7.mp4"
              type="video/mp4"
            />
            {error_msg}
          </video>
        </div>
      </div>
    </div>
  );
};
export default ScanningApp;
