import React, { useEffect } from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import NavBar from "../landingPage/header/navbar";
import { Helmet } from "react-helmet";
import "./index.scss";
import MetaTagContents from "../../MetaTagContents";

const images = [
  {
    name: "camera_1.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_1.jpg",
  },
  {
    name: "camera_10.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_10.jpg",
  },
  {
    name: "camera_2.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_2.jpg",
  },
  {
    name: "camera_3.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_3.jpg",
  },
  {
    name: "camera_4.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_4.jpg",
  },
  {
    name: "camera_5.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_5.jpg",
  },
  {
    name: "camera_6.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_6.jpg",
  },
  {
    name: "camera_7.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_7.jpg",
  },
  {
    name: "camera_8.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_8.jpg",
  },
  {
    name: "camera_9.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_9.jpg",
  },
  {
    name: "camera_11.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_11.jpg",
  },
  {
    name: "camera_12.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_12.jpg",
  },
  {
    name: "camera_13.jpg",
    src: "https://all3d.s3.amazonaws.com/scene_renders/672/camera_13.jpg",
  },
];

const PreppyRenders = () => {
  let RendersGrid = (
    <Row type="flex" style={{ padding: 12, margin: 5 }}>
      {images.map((x) => (
        <Col span={12}>
          <Card>
            <a href={x.src} target="_blank">
              <img style={{ width: "100%" }} src={x.src} alt="Preppy Render" />
            </a>
          </Card>
        </Col>
      ))}
    </Row>
  );
  const { name, content } = MetaTagContents.PREPPY_RENDERS;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.PREPPY_RENDERS.title}</title>
        <link
          rel="canonical"
          href="https://all3d.ai/skyline/preppytradional/images"
        />
        <meta name={name} content={content} />
      </Helmet>
      <span>
        <NavBar />
        <div style={{ height: 80 }}></div>
        {RendersGrid}
      </span>
    </div>
  );
};

export default withRouter(PreppyRenders);
