import React, { useEffect } from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import NavBar from "../landingPage/header/navbar";
import { Helmet } from "react-helmet";
import "./index.scss";
import MetaTagContents from "../../MetaTagContents";

const images = [
  {
    name: "Deco Glam Image 1 Living Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%201%20Living%20Room.jpg",
  },
  {
    name: "Deco Glam Image 2 Living Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%202%20Living%20Room.jpg",
  },
  {
    name: "Deco Glam Image 10 Office.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%2010%20Office.jpg",
  },
  {
    name: "Deco Glam Image 5 Kitchen.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%205%20Kitchen.jpg",
  },
  {
    name: "Deco Glam Image 3 Living Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%203%20Living%20Room.jpg",
  },
  {
    name: "Deco Glam image 4a Living Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20image%204a%20Living%20Room.jpg",
  },
  {
    name: "Deco Glam Image 4 Living Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%204%20Living%20Room.jpg",
  },
  {
    name: "Still2.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Still2.jpg",
  },
  {
    name: "Deco Glam Image 6 Dinning room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%206%20Dinning%20room.jpg",
  },
  {
    name: "Deco Glam Image 7 Dinning Room.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%207%20Dinning%20Room.jpg",
  },
  {
    name: "DecoGlam_Still11.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/DecoGlam_Still11.jpg",
  },
  {
    name: "DecoGlam_Still12.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/DecoGlam_Still12.jpg",
  },
  {
    name: "Deco Glam Image 9 Office.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Deco%20Glam%20Image%209%20Office.jpg",
  },
  {
    name: "Still5 (2).jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/Still5%20%282%29.jpg",
  },
  {
    name: "DecoGlam_Still10.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/DecoGlam_Still10.jpg",
  },
  {
    name: "DecoGlam_Still8.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/deco-glam/DecoGlam_Still8.jpg",
  },
];

const SkylineDecoRenders = () => {
  let RendersGrid = (
    <Row type="flex" style={{ padding: 12, margin: 5 }}>
      {images.map((x) => (
        <Col span={12}>
          <Card>
            <a href={x.src} target="_blank">
              <img
                style={{ width: "100%" }}
                src={x.src}
                alt="Skyline Deco Render"
              />
            </a>
          </Card>
        </Col>
      ))}
    </Row>
  );
  const { name, content } = MetaTagContents.SKYLINE_DECO_RENDERS;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.SKYLINE_DECO_RENDERS.title}</title>
        <link rel="canonical" href="https://all3d.ai/skyline/decoglam/images" />
        <meta name={name} content={content} />
      </Helmet>
      <span>
        <NavBar />
        <div style={{ height: 80 }}></div>
        {RendersGrid}
      </span>
    </div>
  );
};

export default withRouter(SkylineDecoRenders);
