import React from "react";
import { useEffect, useState } from "react";
import "./index.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Card } from "antd";
import * as ENVIRONMENT from "../../../env";
import ThreeSixtyViewer from "../../../ThreeSixtyViewer";
import TextConstants from "../../../TextConstants";

const Create3DTours = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};

const { title } = TextConstants.CREATE_3D_TOURS;
const WebVersion = () => {
  const [threeSixty, setThreeSixty] = useState(null);

  useEffect(() => {
    if (threeSixty == null) {
      setThreeSixty(
        new ThreeSixtyViewer(
          "vs-container-sec",
          `loading-container-sec`,
          ENVIRONMENT.SCENE_BASE_URL + 4198 + "/" + "360Camera.jpg",
          false,
          "web"
        )
      );
    }
  }, []);

  return (
    <div
      className="d-none d-lg-block"
      style={{ width: "100%", textAlign: "center" }}
    >
      <div
        className="justify-space-between tour-section"
        style={{ padding: "60px 10%", marginTop: 100 }}
      >
        <div className="row justify-content-md-center">
          <div className="col-lg-10 col-12 justify-in-center">
            <h4
              className="font graphik-medium f-18 w-500 black-2b"
              style={{ marginBottom: 30 }}
            >
              {title}
            </h4>
          </div>
          <div className="col-lg-12 col-12">
            <Card id={`vs-container-sec`} style={{ cursor: "grabbing" }}>
              <div
                id={"360Camera"}
                style={{ cursor: "grabbing", background: "white" }}
              ></div>
              <div id={`loading-container-sec`}></div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  const [threeSixty, setThreeSixty] = useState(null);

  useEffect(() => {
    if (threeSixty == null) {
      setThreeSixty(
        new ThreeSixtyViewer(
          "vs-container-mobile",
          `loading-container-mobile`,
          ENVIRONMENT.SCENE_BASE_URL + 4198 + "/" + "360Camera.jpg",
          false,
          "mobile"
        )
      );
    }
  }, []);

  return (
    <div className="d-lg-none">
      <div className="tour-section">
        <div className="row justify-content-md-center">
          <div className="col-12 justify-in-center">
            <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          </div>
          <div className="col-12">
            <div
              id={`vs-container-mobile`}
              style={{ cursor: "grabbing", background: "white" }}
            ></div>
            <div id={`loading-container-mobile`}>
              <LoadingOutlined
                className="center-loader  space-360-loader"
                id={`loadingImages-360Camera`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Create3DTours;
