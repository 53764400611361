import React, { useState, useEffect }  from 'react';
import { PlusOutlined, LoadingOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Row, Col, Menu, Dropdown, notification, Form, Input, Select, Button, Card, Drawer, message, TreeSelect, Checkbox, Tag, Tooltip, Slider, Tabs, Radio, Breadcrumb, Empty, Pagination } from 'antd';
import './index.scss';
import FileConstants from '../../FileConstants';

const StoreContent = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        forceUpdate();
    },[props.itemType])

  return (<Col lg={6} sm={24} md={8} style={{padding : "10px"}} key={props.index}>
  <Card className="card-shadow" bordered={false}>
    <a href={props.itemType == 'products' ? `/product-assets/${props.id}`: props.itemType == 'spaces' ?  `/room-assets/${props.id}` :  `/scene-assets/${props.id}` } className="suggested-product" >
      <div className="suggested-product apply-Avenir">
        <div className="store-card" style={{textAlign: 'center', display:'flex', justifyContent: 'center'}}>
            <img key={props.id}  className="product-image" src={props.thumbnail || '/space_incomplete.svg'} style={{objectFit: "contain"}} alt="Product Image"/>
        </div>
        <Tooltip title={<span className='manrope f-12 white'>{props.name}</span>}>
          <div className="manrope f-18 w-500 black-00 clamp-text w-90" style= {{margin:"10px 20px 5px 20px", textAlign: 'center'}}>
              {props.name}
          </div>
        </Tooltip>
        {props.category ? <>
          <div className="justify-in-center manrope f-14 grey-77 capitalize" style= {{marginBottom:"5px"}}> 
          <div>Category:</div>&nbsp;
          <div style={{"color":"#333333"}}>{props.category}</div>
        </div>
        </> : ""}
        {props.company_display_name? <>
          <div className="justify-in-center manrope f-14 grey-77 capitalize"  style= {{marginBottom:"8px"}}> 
          <div>Brand:</div> &nbsp;
          <div style={{"color":"#333333"}}>{props.company_display_name}</div>
        </div>
        </> : ""}
        <div className="justify-in-center manrope f-14 grey-77 capitalize" style= {{marginBottom:"8px", marginTop: 8}}> 
          {props.price == 0 ?
            <div style={{"color":"#333333"}}>Free</div> :
            props.company_id && FileConstants.ALL3D_COMPANY_IDS().includes(props.company_id.toString()) && props.price && props.price !=0 ?
            <div style={{"color":"#333333"}}>Included in platform fee</div> :
            props.price ?
            <>
              <div >Price:</div> &nbsp;
              <div style={{"color":"#333333"}}>${props.price}</div>
            </>: ''}
        </div>
      </div>
    </a>
  </Card>
</Col>);
};



export default StoreContent;