import React, { useEffect, useState } from "react";
import NavBar from "./navbar";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import VirtualShowroom from "../../../VirtualShowroom";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import { Button, Modal } from "antd";
import ModelViewer from "../sectionTwo/modelViewer";
const isTouchDevice = require("is-touch-device");

const Header = (props) => {
  const [virtualShowroom, setVirtualShowroom] = useState(null);
  const [headerClass, setHeaderClass] = useState("header-sec");
  const [threedTour, setThreedTour] = useState(
    JSON.stringify(FileConstants.threed_tour)
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setHeaderClass(headerClass + " visible");
  }, []);

  // useEffect(() => {
  //   if (virtualShowroom === null) {
  //     if (threedTour != "") {
  //       setVirtualShowroom(
  //         new VirtualShowroom(
  //           threedTour,
  //           false,
  //           "hotspot",
  //           "vs-container",
  //           "loading-container",
  //           "loadingImage"
  //         )
  //       );
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (virtualShowroom != null) {
  //     const img = new Image();
  //     img.onload = () => {
  //       setIsVisible(true);
  //     };
  //     img.src = "/tour-bg.jpg";
  //   }
  // }, [virtualShowroom]);

  return (
    <div className={headerClass}>
      <div className="rel">
        <NavBar />
        <div className="container-fluid pd-0">
          <div className="row align-items-center header-flex-no-wrap">
            <div className="col-lg-7 col-12 header-2 d-flex align-items-center header-text-style">
              <div style={{width: '70%'}} className="d-none d-lg-block">
                <h4 className="manrope f-24 black w-600 header-text-style small-heading">
                  The Future of Ecommerce
                </h4>
                <h1 className="manrope f-46 black mb-0 big-heading">
                  AI-Powered 3D Models
                </h1>
                <h1 className="manrope f-46 blue big-heading">for Online Shopping</h1>
                <Link
                  to={{ pathname: "https://app.all3d.ai/register" }}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="btn get-started-btn-blue font graphik-regular f-16 w-500 white"
                  >
                    Get Started for Free
                  </button>
                </Link>
              </div>

              <div style={{width: '90%'}} className="d-lg-none">
                <h4 className="manrope f-16 black w-600 header-text-style small-heading">
                  The Future of Ecommerce
                </h4>
                <h1 className="manrope black mb-0 big-heading">
                  AI-Powered 3D Models
                </h1>
                <h1 className="manrope blue big-heading">for Online Shopping</h1>
               
              </div>
            </div>
            <div className="col-lg-6 col-12 model-right-pd">
              <ModelViewer class='virtual-product header model-viewer-pos'
              poster={"https://d48f7equ64qjl.cloudfront.net/product_assets/perspective_renders/28795_FrontAngled.jpg"}
              src={"https://d48f7equ64qjl.cloudfront.net/static_files/hero_section_assets/28795.glb"} />
            </div>
          </div>
        </div>
        {/* <div id={`vs-container`} style={{ cursor: "grabbing" }}>
          <div className="hotspot" id="hotspot">
            <img
              className="hotspotImage"
              src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif"
              alt="Hotspot"
            />
            <span className="tooltiptext" id="toolTip"></span>
          </div>
        </div> */}

        {/* <div id="loading-container">
          <img
            id="loadingImage"
            className="loadingImage"
            src=""
            style={{ width: "100%", height: "100%" }}
            alt="loading image"
          ></img>
        </div> */}
      </div>
      {/* <HomePageCTA isVisible={isVisible} /> */}
    </div>
  );
};

const { title, btn_text, access_msg } = TextConstants.HEADER;

const HomePageCTA = (props) => {
  return (
    <div>
      <Animated
        animationIn="fadeInUp"
        animationInDuration={1000}
        isVisible={props.animationVisibility}
        className="cta-text"
        style={{ "pointer-events": "none" }}
      >
        <div className="d-none d-lg-block">
          <div className="slider-text" style={{ marginBottom: 28 }}>
            <h1 className="first-call font lora ws-8 f-50 w-400 white">
              {title}
            </h1>
          </div>
          <Link
            to={{ pathname: "https://app.all3d.ai/register" }}
            target="_blank"
          >
            <button
              type="button"
              className="btn get-started-btn font graphik-regular f-16 w-500 white"
            >
              {btn_text}
            </button>
          </Link>
        </div>
        <div id="homepage" className="d-lg-none">
          <div
            className="slider-text justify-in-center"
            style={{ marginBottom: 20 }}
          >
            <div className="first-call font lora ws-2 f-24 w-400 white">
              {title}
            </div>
          </div>
          <Link
            to={{ pathname: "https://app.all3d.ai/register" }}
            target="_blank"
          >
            <button
              type="button"
              className="btn get-started-btn font graphik-regular f-14 w-500 white"
            >
              {btn_text}
            </button>
          </Link>
        </div>
      </Animated>
      <div id="permission" className="gyro-permission">
        <h2
          className="font graphik-regular f-16 black-2b"
          style={{ marginBottom: 20 }}
        >
          {access_msg}
        </h2>
        <div className="justify-in-center">
          <Button
            className="modal-okay-blue square font-12"
            id="permission-no"
            style={{ "margin-right": "10px" }}
          >
            No
          </Button>
          <Button className="modal-okay square font-12" id="permission-yes">
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
