import React from "react";
import TextConstants from "../../../TextConstants";

function Details() {
  return (
    <>
      <div className="description-container">
        <div className="description">
          <div
            className="row justify-content-md-center"
            style={{ padding: 28 }}
          >
            <WebVersion />
            <MobileVersion />
          </div>
        </div>
      </div>
    </>
  );
}

const { HIGH_POINT_DESCRIPTION_1, HIGH_POINT_DESCRIPTION_2 } = TextConstants;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <p
        style={{
          fontSize: 22,
          marginBottom: 0,
          fontWeight: 600,
          paddingRight: 12,
        }}
      >
        {HIGH_POINT_DESCRIPTION_1}
        <br />
        <br />
        {HIGH_POINT_DESCRIPTION_2}
      </p>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="col-lg-10 col-12 font lora" id="textSection">
        <p
          style={{
            fontSize: 18,
            marginBottom: 0,
            fontWeight: 500,
          }}
        >
          {HIGH_POINT_DESCRIPTION_1}
          <br />
          <br />
          {HIGH_POINT_DESCRIPTION_2}
        </p>
      </div>
    </div>
  );
};

export default Details;
