import React from 'react';
// import "@google/model-viewer";
const isTouchDevice = require('is-touch-device');


function ModelViewer(props) {
  let policy = 'always-allow'
  if (isTouchDevice()) {
    policy = 'allow-when-focused'
  }
  let loaderProps = {}
  if (!props.src) {
    loaderProps = {
      loading: "eager",
      poster: "10826_HeadOn-min.jpg"
    }
  }

return (

  <React.Fragment>
    <model-viewer environment-image="neutral" 
    class={"disablehover model-viewer-class " + props.class} 
    {...loaderProps} 
    ar 
    data-js-focus-visible 
    poster={props.poster ? props.poster : ""}
    shadow-intensity={1} 
    interaction-policy={policy} 
    src={props.src ? props.src : "https://d48f7equ64qjl.cloudfront.net/product_assets/glb/10826.glb"}
    alt="Velvet Hagen Dining Chair" 
    auto-rotate 
    camera-controls 
    interaction-prompt-threshold={0} 
    ar-modes="webxr scene-viewer quick-look" 
    background-color="#FFFFFF">
    </model-viewer>

  </React.Fragment>

);
}


export default ModelViewer;
