import React from "react";
import "./SubscriptionPlans.scss";
import { Table, Collapse, Tabs, Card, Layout, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import FileConstants from "./../../FileConstants";
import MetaTagContents from "../../MetaTagContents";
import TextConstants from "../../TextConstants";
import { ArrowRightOutlined } from "@ant-design/icons";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Content } = Layout;

function getTableColumn(col, columnName, name = false) {
  let data = columnName;
  let new_data = [];
  for (let data_index in data) {
    let data_object = {};
    data_object["colSpan"] = 0;
    if (data[data_index]["dataIndex"] === "Replace") {
      data_object["width"] = 100;
      data_object["dataIndex"] = col;
      data_object["key"] = col;
      data_object["colSpan"] = 0;
      new_data.push(data_object);
    } else if (name === false) {
      data_object["title"] = data[data_index]["title"];
      data_object["dataIndex"] = data[data_index]["dataIndex"];
      data_object["key"] = data[data_index]["key"];
      data_object["width"] = 140;
      data_object["colSpan"] = 2;
      new_data.push(data_object);
    }
  }
  return new_data;
}

let getTableData = (col, table) => {
  let data = table;
  let new_data = [];
  for (let data_index in data) {
    let data_object = {};
    data_object["Name"] = data[data_index]["Name"];
    data_object[col] = data[data_index][col];
    data_object["key"] = data_index;
    new_data.push(data_object);
  }
  return new_data;
};

function SubscriptionPlans() {
  const { Meta } = Card;
  const { name, content } = MetaTagContents.SUBSCRIPTION_PLANS;
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.SUBSCRIPTION_PLANS.title}</title>
        <link rel="canonical" href="https://all3d.ai/pricing" />
        <meta name={name} content={content} />
      </Helmet>

      <NavBar />

      <Layout>
        <WebVersion />
        <MobileVersion />
      </Layout>
      <Footer />
    </React.Fragment>
  );
}

const {
  title,
  unit_price_link_text,
  text_1,
  contact_us_link_text,
  text_2,
  plans,
} = TextConstants.SUBSCRIPTION_PLANS;

const WebVersion = () => {
  return (
    <Content className="d-none d-xl-block">
      <div>
        <div style={{ marginTop: "140px" }}>
          <div className="justify-in-center">
            <h1 className="font lora w-400 f-32 ws-6 black-2b">{title}</h1>
          </div>
          <div
            className="font graphik-regular"
            style={{ margin: "40px 140px" }}
          >
            <div className="prices">
              <Table
                tableLayout="fixed"
                showHeader={false}
                dataSource={FileConstants.PlansData}
                columns={FileConstants.PhotoServicesColumns}
                pagination={false}
                hoverable={false}
              />
            </div>
            <div className="pricing-tables">
              <Table
                tableLayout="fixed"
                dataSource={FileConstants.PhotoServicesData}
                columns={FileConstants.PhotoServicesColumns}
                pagination={false}
              />
              <Table
                tableLayout="fixed"
                dataSource={FileConstants.AppPurchasesData}
                columns={FileConstants.AppPurchasesColumns}
                pagination={false}
              />
              <Table
                tableLayout="fixed"
                dataSource={FileConstants.ConfiguratorData}
                columns={FileConstants.ConfiguratorColumns}
                pagination={false}
              />
              <Table
                tableLayout="fixed"
                dataSource={FileConstants.SupportData}
                columns={FileConstants.SupportColumns}
                pagination={false}
              />
            </div>
            {/* <div
              style={{
                color: "#276DD7",
                background: "rgba(39, 109, 215, 0.08)",
                width: "100%",
                padding: "16px",
                borderRadius: "4px",
              }}
              className="justify-in-center"
            >
              <a href="/unit-price" className="justify-in-center">
                {unit_price_link_text}{" "}
                <ArrowRightOutlined style={{ marginLeft: "4px" }} />
              </a>
            </div> */}
            <p
              className="font graphik-regular f-14 black-2b"
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              {text_1} <a href="/contact-us">{contact_us_link_text}</a> {text_2}
            </p>
            <div style={{ marginTop: "30px", marginBottom: "30px" }} id="faqs">
              <div
                className="justify-in-center"
                style={{ marginBottom: "10px" }}
              >
                <span className="font lora w-400 f-32 ws-6 black-2b">FAQs</span>
              </div>
              <Collapse accordion ghost={true} expandIconPosition={"right"}>
                {TextConstants.FAQS.map((faq, index) => (
                  <Panel
                    header={
                      <span className="font graphik-medium f-16 black-55">
                        {faq.Q}
                      </span>
                    }
                    key={index}
                  >
                    <div className="font graphik-regular f-14 black-2b">
                      {faq.A}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

const MobileVersion = () => {
  return (
    <Content className="d-xl-none">
      <div>
        <div style={{ marginTop: "100px" }}>
          <div className="justify-in-center">
            <span className="font lora w-400 f-20 ws-2 black-2b">{title}</span>
          </div>
          <div className="justify-in-center resp" style={{ padding: 14 }}>
            <Tabs>
              {/* Free */}
              <TabPane tab={plans[0]} key="1">
                <div className="prices">
                  <Table
                    showHeader={false}
                    dataSource={getTableData(plans[0], FileConstants.PlansData)}
                    columns={getTableColumn(
                      plans[0],
                      FileConstants.PhotoServicesColumnsResponsive,
                      true
                    )}
                    pagination={false}
                  />
                </div>
                <div className="pricing-tables">
                  <Table
                    dataSource={getTableData(
                      plans[0],
                      FileConstants.PhotoServicesData
                    )}
                    columns={getTableColumn(
                      plans[0],
                      FileConstants.PhotoServicesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[0],
                      FileConstants.AppPurchasesData
                    )}
                    columns={getTableColumn(
                      plans[0],
                      FileConstants.AppPurchasesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[0],
                      FileConstants.ConfiguratorData
                    )}
                    columns={getTableColumn(
                      plans[0],
                      FileConstants.ConfiguratorColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[0],
                      FileConstants.SupportData
                    )}
                    columns={getTableColumn(
                      plans[0],
                      FileConstants.SupportColumnsResponsive
                    )}
                    pagination={false}
                  />
                </div>
                {/* <div
                  style={{
                    color: "#276DD7",
                    background: "rgba(39, 109, 215, 0.08)",
                    padding: "16px",
                    borderRadius: "4px",
                  }}
                  className="justify-in-center"
                >
                  <a href="/unit-price" className="justify-in-center">
                    {unit_price_link_text}{" "}
                    <ArrowRightOutlined style={{ marginLeft: "4px" }} />
                  </a>
                </div> */}
                <p
                  className="font graphik-regular f-12 black-2b"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  {text_1} <a href="/contact-us">{contact_us_link_text}</a>{" "}
                  {text_2}
                </p>
              </TabPane>
              {/* Pro */}
              <TabPane tab={plans[1]} key="2">
                <div className="prices">
                  <Table
                    showHeader={false}
                    dataSource={getTableData(plans[1], FileConstants.PlansData)}
                    columns={getTableColumn(
                      plans[1],
                      FileConstants.PhotoServicesColumnsResponsive,
                      true
                    )}
                    pagination={false}
                  />
                </div>
                <div className="pricing-tables">
                  <Table
                    dataSource={getTableData(
                      plans[1],
                      FileConstants.PhotoServicesData
                    )}
                    columns={getTableColumn(
                      plans[1],
                      FileConstants.PhotoServicesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[1],
                      FileConstants.AppPurchasesData
                    )}
                    columns={getTableColumn(
                      plans[1],
                      FileConstants.AppPurchasesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[1],
                      FileConstants.ConfiguratorData
                    )}
                    columns={getTableColumn(
                      plans[1],
                      FileConstants.ConfiguratorColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[1],
                      FileConstants.SupportData
                    )}
                    columns={getTableColumn(
                      plans[1],
                      FileConstants.SupportColumnsResponsive
                    )}
                    pagination={false}
                  />
                </div>
                {/* <div
                  style={{
                    color: "#276DD7",
                    background: "rgba(39, 109, 215, 0.08)",
                    padding: "16px",
                    borderRadius: "4px",
                  }}
                  className="justify-in-center"
                >
                  <a href="/unit-price" className="justify-in-center">
                    {unit_price_link_text}
                    <ArrowRightOutlined style={{ marginLeft: "4px" }} />
                  </a>
                </div> */}
                <p
                  className="font graphik-regular f-12 black-2b"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  {text_1} <a href="/contact-us">{contact_us_link_text}</a>{" "}
                  {text_2}
                </p>
              </TabPane>
              {/* Premium */}
              <TabPane tab={plans[2]} key="3">
                <div className="prices">
                  <Table
                    showHeader={false}
                    dataSource={getTableData(plans[2], FileConstants.PlansData)}
                    columns={getTableColumn(
                      plans[2],
                      FileConstants.PhotoServicesColumnsResponsive,
                      true
                    )}
                    pagination={false}
                  />
                </div>
                <div className="pricing-tables">
                  <Table
                    dataSource={getTableData(
                      plans[2],
                      FileConstants.PhotoServicesData
                    )}
                    columns={getTableColumn(
                      plans[2],
                      FileConstants.PhotoServicesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[2],
                      FileConstants.AppPurchasesData
                    )}
                    columns={getTableColumn(
                      plans[2],
                      FileConstants.AppPurchasesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[2],
                      FileConstants.ConfiguratorData
                    )}
                    columns={getTableColumn(
                      plans[2],
                      FileConstants.ConfiguratorColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[2],
                      FileConstants.SupportData
                    )}
                    columns={getTableColumn(
                      plans[2],
                      FileConstants.SupportColumnsResponsive
                    )}
                    pagination={false}
                  />
                </div>
                {/* <div
                  style={{
                    color: "#276DD7",
                    background: "rgba(39, 109, 215, 0.08)",
                    padding: "16px",
                    borderRadius: "4px",
                  }}
                  className="justify-in-center"
                >
                  <a href="/unit-price" className="justify-in-center">
                    {unit_price_link_text}
                    <ArrowRightOutlined style={{ marginLeft: "4px" }} />
                  </a>
                </div> */}
                <p
                  className="font graphik-regular f-12 black-2b"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  {text_1} <a href="/contact-us">{contact_us_link_text}</a>{" "}
                  {text_2}
                </p>
              </TabPane>
              {/* Enterprise */}
              <TabPane tab={plans[3]} key="4">
                <div className="prices">
                  <Table
                    showHeader={false}
                    dataSource={getTableData(plans[3], FileConstants.PlansData)}
                    columns={getTableColumn(
                      plans[3],
                      FileConstants.PhotoServicesColumnsResponsive,
                      true
                    )}
                    pagination={false}
                  />
                </div>
                <div className="pricing-tables">
                  <Table
                    dataSource={getTableData(
                      plans[3],
                      FileConstants.PhotoServicesData
                    )}
                    columns={getTableColumn(
                      plans[3],
                      FileConstants.PhotoServicesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[3],
                      FileConstants.AppPurchasesData
                    )}
                    columns={getTableColumn(
                      plans[3],
                      FileConstants.AppPurchasesColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[3],
                      FileConstants.ConfiguratorData
                    )}
                    columns={getTableColumn(
                      plans[3],
                      FileConstants.ConfiguratorColumnsResponsive
                    )}
                    pagination={false}
                  />
                  <Table
                    dataSource={getTableData(
                      plans[3],
                      FileConstants.SupportData
                    )}
                    columns={getTableColumn(
                      plans[3],
                      FileConstants.SupportColumnsResponsive
                    )}
                    pagination={false}
                  />
                </div>
                {/* <div
                  style={{
                    color: "#276DD7",
                    background: "rgba(39, 109, 215, 0.08)",
                    padding: "16px",
                    borderRadius: "4px",
                  }}
                  className="justify-in-center"
                >
                  <a href="/unit-price" className="justify-in-center">
                    {unit_price_link_text}
                    <ArrowRightOutlined style={{ marginLeft: "4px" }} />
                  </a>
                </div> */}
                <p
                  className="font graphik-regular f-12 black-2b"
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                >
                  {text_1} <a href="/contact-us">{contact_us_link_text}</a>{" "}
                  {text_2}
                </p>
              </TabPane>
            </Tabs>
          </div>

          <div style={{ padding: 15 }}>
            <div className="justify-in-center" style={{ marginBottom: "10px" }}>
              <h2 className="font lora ws-2 f-20 black-2b">FAQs</h2>
            </div>
            <Collapse accordion ghost={true} expandIconPosition={"right"}>
              {TextConstants.FAQS.map((faq, index) => (
                <Panel
                  header={
                    <span className="font graphik-medium f-14 black-55">
                      {faq.Q}
                    </span>
                  }
                  key={index}
                >
                  <div className="font graphik-regular f-12 black-2b">
                    {faq.A}
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default SubscriptionPlans;
