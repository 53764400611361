import React from "react";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

function Storefronts() {
  return (
    <>
      <div className="row justify-content-md-center">
        <WebVersion />
        <MobileVersion />
      </div>
    </>
  );
}

const { store, lifestyle, space, virtual_tour } =
  FileConstants.HIGH_POINT_LANDING_PAGE;

const { title, heading, _store, _lifestyle, _space, _virtual_tour } =
  TextConstants.STOREFRONTS;

const WebVersion = () => {
  return (
    <div className="d-none d-lg-block" style={{ width: "100%", margin: 0 }}>
      <div
        className="col-lg-12 col-12 immersive-storefronts"
        id="textSection"
        style={{ width: "65%", textAlign: "center" }}
      >
        <h3
          className="font graphik-medium f-20 brown justify-in-center"
          style={{ marginBottom: 8, marginTop: 32 }}
        >
          {title}
        </h3>
        <h1
          className="font lora f-48  w-700 black-2b justify-in-center"
          style={{ marginBottom: 0 }}
        >
          {heading}
        </h1>
      </div>

      {/* ALL3D Store */}
      <div
        className="row justify-space-between immersive-storefronts"
        style={{ marginTop: 40 }}
      >
        <div className="col-lg-6 col-12 bg-img">
          <img className="section-img load-lazy" src={store} alt="index" />
        </div>
        <div className="col-lg-6 col-12" style={{ paddingLeft: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">
            {_store.title}
          </h4>
          <p className="font graphik-regular f-16 w-400 grey-light">
            {_store.text}
          </p>
        </div>
      </div>
      {/* Lifestyle Images */}
      <div
        className="row justify-space-between immersive-storefronts"
        style={{ marginTop: 8 }}
      >
        <div className="col-lg-6 col-12" style={{ paddingRight: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">
            {_lifestyle.title}
          </h4>
          <p className="font graphik-regular f-16 w-400 grey-light">
            {_lifestyle.text}
          </p>
        </div>
        <div className="col-lg-6 col-12 bg-img" style={{ paddingLeft: 50 }}>
          <img className="section-img load-lazy" src={lifestyle} alt="index" />
        </div>
      </div>
      {/* 3D Spaces and Showrooms */}
      <div
        className="row justify-space-between immersive-storefronts"
        style={{ marginTop: 40 }}
      >
        <div className="col-lg-6 col-12 bg-img">
          <img className="section-img load-lazy" src={space} alt="index" />
        </div>
        <div className="col-lg-6 col-12" style={{ paddingLeft: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">
            {_space.title}
          </h4>
          <p className="font graphik-regular f-16 w-400 grey-light">
            {_space.text}
          </p>
        </div>
      </div>
      {/* Virtual Tours */}
      <div
        className="row justify-space-between immersive-storefronts"
        style={{ marginTop: 8 }}
      >
        <div className="col-lg-6 col-12" style={{ paddingRight: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">
            {_virtual_tour.title}
          </h4>
          <p className="font graphik-regular f-16 w-400 grey-light">
            {_virtual_tour.text}
          </p>
        </div>
        <div className="col-lg-6 col-12 bg-img" style={{ paddingLeft: 50 }}>
          <img
            className="section-img load-lazy"
            src={virtual_tour}
            alt="index"
          />
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none" style={{ width: "100%", textAlign: "center" }}>
      <div
        className="col-12 "
        id="textSection"
        style={{ padding: 0, margin: 0 }}
      >
        <h3
          className="font graphik-medium f-20 brown justify-in-center"
          style={{ marginBottom: 6, marginTop: 32 }}
        >
          {title}
        </h3>
        <h1
          className="font lora f-32  w-700 black-2b justify-in-center"
          style={{ marginBottom: 0 }}
        >
          {heading}
        </h1>
      </div>

      {/* ALL3D Store */}
      <div className="immersive-storefronts" style={{ marginTop: 20 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">
            {_store.title}
          </h4>
          <p className="font graphik-regular f-14 w-400 grey-light">
            {_store.text}
          </p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img className="section-img load-lazy" src={store} alt="index" />
        </div>
      </div>

      {/* Lifestyle Images */}
      <div className="immersive-storefronts" style={{ marginTop: 20 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">
            {_lifestyle.title}
          </h4>
          <p className="font graphik-regular f-14 w-400 grey-light">
            {_lifestyle.text}
          </p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img className="section-img load-lazy" src={lifestyle} alt="index" />
        </div>
      </div>
      {/* 3D Spaces and Showrooms */}
      <div className="immersive-storefronts" style={{ marginTop: 20 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">
            {_space.title}
          </h4>
          <p className="font graphik-regular f-14 w-400 grey-light">
            {_space.text}
          </p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img className="section-img load-lazy" src={space} alt="index" />
        </div>
      </div>
      {/* Virtual Tours */}
      <div
        className="immersive-storefronts"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">
            {_virtual_tour.title}
          </h4>
          <p className="font graphik-regular f-14 w-400 grey-light">
            {_virtual_tour.text}
          </p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img
            className="section-img load-lazy"
            src={virtual_tour}
            alt="index"
          />
        </div>
      </div>
    </div>
  );
};

export default Storefronts;
