import React, { useState, useEffect, useCallback } from 'react';
import {
    Row,
    Col,
    Card,
    Tooltip,
    Image
} from 'antd';
import CookieConsentBanner from '../cookieConsentBanner';
import NavBar from '../landingPage/header/navbar';
import Footer from '../landingPage/footer'
import './index.scss'
import * as ENVIRONMENT from '../../env';
import FileConstants from '../../FileConstants';
import ResponsiveProductDetail from './ResponsiveProductCard';

const ResponsiveProductPage = (props) => {

    return (
        <React.Fragment>
            <NavBar />
             
            <div className="assets-visible" >
            <Row type="flex" justify="space-around" style={{marginTop:"100px"}}>
                <Col id="centerAlign" span={24}>
                <CookieConsentBanner/>
                   
                 <ResponsiveProductDetail productDetails={props.productDetails}
                 productReducer={props.productReducer}/>
                        
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-name-h3">{props.productReducer.product.product_name}</h3>
                    </Row>
                    <Row type="flex" style={{ justifyContent: "space-between",marginTop: 10}} className="margin-class">
                        {(props.productDetails!=null && props.productDetails.price && props.productDetails.price!=0) ? <h3 className="manrope f-20 green-75 w-500">{'Price: $' + props.productDetails.price.toString()} </h3>: <h3 className="manrope f-20 green-75 w-500">Free</h3>}          

                    </Row>
                    <hr className="margin-class-hr"/>
                    <Row type="flex" style={{ justifyContent: "flex-start"}} className="margin-class">
                        <h3 className="manrope f-18 black-55 w-600">Basic Details</h3>
                    </Row>

                    {props.productDetails && props.productDetails.category ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                            Category 
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productDetails.category}
                        </h3>
                       
                    </Row>: ''}
                    {props.productDetails && props.productDetails.style_category ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                            Style Category
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productDetails.style_category}
                        </h3>
                       
                    </Row>: ''}
                    {props.productDetails && props.productDetails.licensing_options && props.productDetails.licensing_options.license ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                            License
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productDetails.licensing_options.license == 'private' ? props.productDetails.licensing_options.license : ''}
                            {props.productDetails.licensing_options.license == 'public' ? props.productDetails.licensing_options.public_option == 'forCreativeCommon' ? 
                            <Tooltip title={<span className='manrope f-12 white'>{props.productDetails.licensing_options.creative_common}</span>}>
                                <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.productDetails.licensing_options.creative_common)}/>
                            </Tooltip>: 
                             <span>
                                <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                                    <img alt="License Icon" src={FileConstants.getLicensingIcon(props.productDetails.licensing_options.public_option)}/>
                                </Tooltip>
                                &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}><img alt="Can't Download" src='/not_downloadable.png'/></Tooltip>
                            </span>
                            : '' }
                        </h3>
                       
                    </Row>: ''}
                   
                   
                   

                    <hr className="margin-class"/>
                    <Row type="flex" style={{ justifyContent: "flex-start"}} className="margin-class">
                        <h3 className="manrope f-18 black-55 w-600">Product Model Information</h3>
                    </Row>
                    {props.productDetails ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                          Height
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                        {props.productDetails.model_info != undefined ? 
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.model_info.height)} inches 
                        </span>
                        :
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.height)} inches                                                
                        </span>}
                        </h3>
                       
                    </Row>: ''}
                    {props.productDetails ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                          Width
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                        {props.productDetails.model_info != undefined ? 
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.model_info.width)} inches 
                        </span>
                        :
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.width)} inches                                                
                        </span>}
                        </h3>
                    </Row>: ''}
                    {props.productDetails ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                          Depth
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                        {props.productDetails.model_info != undefined ? 
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.model_info.depth)} inches 
                        </span>
                        :
                        <span style={{color:"#333333",fontSize:"16px"}}>
                            {Math.round(props.productDetails.length)} inches                                                
                        </span>}
                        </h3>
                    </Row>: ''}
                    {props.productDetails && props.productDetails.color ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                           Color
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productDetails.color}
                        </h3>
                    </Row>: ''}
                    {props.productDetails && props.productDetails.similar_color ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                           Closest Color
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productDetails.similar_color}
                        </h3>
                    </Row>: ''}

                    {props.productDetails && props.productDetails.materials && props.productDetails.materials.length > 0 ?
                    <Row type="flex" style={{ justifyContent: "space-between"}} className="margin-class">
                        <h3 className="product-detail-list" style={{marginRight:"40px"}}>
                           Materials
                        </h3>
                        <h3 className="product-detail-list"  style={{fontWeight:"bold",color:"#333333"}}>
                        {props.productDetails.materials.map((ele, index) => (
                            index> 0 ? `, ${ele}` : `${ele}`
                        ))}
                        
                        </h3>
                    </Row>: ''}

  

                

                </Col>
            </Row>
        </div>
        </React.Fragment>
    );


}

export default ResponsiveProductPage;