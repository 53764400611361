import React from "react";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import CookieConsentBanner from "../cookieConsentBanner";
import TextConstants from "../../TextConstants";

function ComingSoon() {
  const { title } = TextConstants.COMING_SOON;
  return (
    <React.Fragment>
      <NavBar />
      <div className="coming-soon">
        <div className="cm-text">{title}</div>
      </div>
      <CookieConsentBanner />

      <Footer />
    </React.Fragment>
  );
}

export default ComingSoon;
