import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Tabs, Card, Layout, Tooltip, Image } from "antd";
import { Link } from "react-router-dom";
import CookieConsentBanner from "../cookieConsentBanner";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import "./index.scss";
import * as ENVIRONMENT from "../../env";
import axios from "axios";
import FileConstants from "../../FileConstants";
import MetaTagContents from "../../MetaTagContents";
import SceneDetailComponent from "./SceneDetailComponent";
import { Helmet } from "react-helmet";

const { Content } = Layout;
const { TabPane } = Tabs;

const SceneDetails = (props) => {
  const {
    match: { params },
  } = props;
  const [templateDetails, setTemplateDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let payload = {
      id: params.id,
      action: "get",
    };
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload).then((res) => {
      console.log(res.data[0]);
      if (res.data && res.data[0]) {
        setTemplateDetails(res.data[0]);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("product-row");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }, []);
  const { name, content } = MetaTagContents.SCENE_DETAILS;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.SCENE_DETAILS.title}</title>
        <link rel="canonical" href="https://all3d.ai/scene-assets/:id" />
        <meta name={name} content={content} />
      </Helmet>
      {loading ? (
        <div className="loader-rol"></div>
      ) : (
        <React.Fragment>
          <NavBar />
          <div className="assets-visible">
            <Row type="flex" justify="space-around">
              <Col id="centerAlign" lg={20} md={22} sm={24} xs={22}>
                <CookieConsentBanner />

                <Row
                  type="flex"
                  style={{ justifyContent: "space-between", marginTop: 0 }}
                  className="margin-class"
                >
                  <h3 className="product-name-h3">{templateDetails.name}</h3>
                </Row>
                <SceneDetailComponent templateDetails={templateDetails} />
              </Col>
            </Row>
          </div>
          <Layout className="pdp-visible">
            <Content>
              <CookieConsentBanner />
              <Row
                type="flex"
                justify="space-around"
                style={{ marginTop: "100px" }}
              >
                <Col lg={20} md={22} sm={24} xs={22}>
                  <div>
                    {templateDetails && templateDetails.status == "3" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="product-row"
                      >
                        <h2
                          className="manrope f-14 w-600 upper-case"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "10px",
                          }}
                        >
                          <Link id="store-bd" to={"/store"}>
                            Store
                          </Link>{" "}
                          /{" "}
                          <span style={{ color: "#D93025" }}>
                            {templateDetails.name}
                          </span>
                        </h2>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <SceneDetailComponent templateDetails={templateDetails} />
                </Col>
              </Row>
            </Content>
          </Layout>

          <div className="abs-btm">
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SceneDetails;
