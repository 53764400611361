import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import 'cocoen/dist/css/cocoen.min.css'
import {Animated} from "react-animated-css";
import $ from 'jquery';


class SceneCreator extends React.Component {
  constructor(props) {
    super(props)
    this.videoEl = React.createRef();
    this.state = {
      sceneVisible: false
    }
  }
  componentDidMount() {
    // new Cocoen();
    // window.addEventListener('scroll', () => {
    //   var top = $(window).scrollTop() + $(window).height();
    //   var getClassTop = $('#sceneAnimate').offset().top;
    //   if (getClassTop != undefined){
    //       var isVisible = top > getClassTop
    //       if (isVisible){
    //           this.setState({
    //             sceneVisible: true
    //           })
    //       }
    //     }
        //});
  }

  onChangeVisibility = (isVisible) => {
    const node = this.videoEl.current
    if (node) {
      if (isVisible == true) {
        node.play();
      } else {
        node.pause();
      }
    }
  }

  render() {
    return (
      <div className='scene-creator' id="sceneAnimate">

        <div className='mb-4 scene-text-padding'>
          <div className='heading'>Scene Creator</div>
          <div className='section-subpar'>Design Rooms / Spaces and create virtual lifestyle photography and 360s, with an easy to use web based application and render with one click</div>
        </div>
        <VisibilitySensor onChange={this.onChangeVisibility}>
          <div className='scene-video'>
            <div className='container-fluid'>
              <div className='row no-gutters justify-content-center'>
                <div className='col-12 text-center'>
                  <video muted controls loop ref={this.videoEl} playsInline poster="https://res.cloudinary.com/all3d/image/upload/v1600248760/Landing%20Page%20Images/scene_h5e57n_exlnnm.jpg">
                    <source src="landingPage/scene-2.mp4" type="video/mp4" />
                  Your browser does not support HTML5 video.
              </video>

                </div>

              </div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    );
  }
}



export default SceneCreator;
