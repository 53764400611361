import React from "react";
import { useEffect, useState } from "react";
import './index.scss'
import $ from "jquery";
import FileConstants from "../../../FileConstants";
import { Animated } from "react-animated-css";
import LazyLoad from "react-lazyload";
import { Card } from "antd";
import * as ENVIRONMENT from "../../../env";
import AISection from ".";

const AIContentSection = () => {
  return (
    <div className="container-fluid our-product-offering-container ai-content-section">
      <div className="row justify-content-md-center">
        <LazyLoad scroll={true}>
          <AISection />
        </LazyLoad>
      </div>
    </div>
  );
};

export default AIContentSection;
