import React from "react";
import Header from "./header/header";
import FormSection from "./form/form";
import Storefronts from "./storefronts/storefronts";
import Description from "./description/description";
import Footer from "./footer/footer";
import Details from "./details/details";

const HighPointLandingPage = () => {
  return (
    <>
      <Header />
      <Details />
      <FormSection />
      <Storefronts />
      <Description />
      <Footer />
    </>
  );
};

export default HighPointLandingPage;
