import React, { useEffect } from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import NavBar from "../landingPage/header/navbar";
import { Helmet } from "react-helmet";
import "./index.scss";
import MetaTagContents from "../../MetaTagContents";

const images = [
  {
    name: "skyline_barstools .jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/skyline_barstools%20.jpg",
  },
  {
    name: "skyline_chairs.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/skyline_chairs.jpg    ",
  },
  {
    name: "Modern Boho Image 5 Otaman.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%205%20Otaman.jpg",
  },
  {
    name: "Modern Boho Image 9 Velvet soft Pink Chair.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%209%20Velvet%20soft%20Pink%20Chair.jpg",
  },
  {
    name: "Modern Boho Image 8  Monaco Citronella (1).jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%208%20%20Monaco%20Citronella%20%281%29.jpg",
  },
  {
    name: "Modern Boho Image 1 Sectional.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%201%20Sectional.jpg",
  },
  {
    name: "skyline_dining chairs .jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/skyline_dining%20chairs%20.jpg",
  },
  {
    name: "Modern Boho Image 3 Sectional Detail.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%203%20Sectional%20Detail.jpg",
  },
  {
    name: "Modern Boho Image 4 Sheepskin Chair.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%204%20Sheepskin%20Chair.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_12.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_13.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_14.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_15.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_16.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_17.jpg",
  },
  {
    name: "",
    src: "https://all3d.s3.amazonaws.com/scene_renders/617/camera_18.jpg",
  },
  {
    name: "Modern Boho Image 2 Sectional.jpg",
    src: "https://all3d.s3.amazonaws.com/static_files/skyline-stills/moder-boho/Modern%20Boho%20Image%202%20Sectional.jpg",
  },
];

const SkylineBohoRenders = () => {
  let RendersGrid = (
    <Row type="flex" style={{ padding: 12, margin: 5 }}>
      {images.map((x) => (
        <Col span={12}>
          <Card>
            <a href={x.src} target="_blank">
              <img
                style={{ width: "100%" }}
                src={x.src}
                alt="Skyline Boho Render"
              />
            </a>
          </Card>
        </Col>
      ))}
    </Row>
  );
  const { name, content } = MetaTagContents.SKYLINE_BOHO_RENDERS;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.SKYLINE_BOHO_RENDERS.title}</title>
        <link
          rel="canonical"
          href="https://all3d.ai/skyline/modernboho/images"
        />
        <meta name={name} content={content} />
      </Helmet>
      <span>
        <NavBar />
        <div style={{ height: 80 }}></div>
        {RendersGrid}
      </span>
    </div>
  );
};

export default withRouter(SkylineBohoRenders);
