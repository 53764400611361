import React, { useState, useEffect, useCallback } from 'react';
import {
    Row,
    Col,
    Tabs
} from 'antd';
import { getProductRender } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CookieConsentBanner from '../cookieConsentBanner';
import NavBar from '../landingPage/header/navbar';
import ModelViewer from '../landingPage/sectionTwo/modelViewer';
import Carousal from './carousal';
import './index.scss'
import Footer from '../landingPage/footer'

const { TabPane } = Tabs;

const ProductAssets = (props) => {

    const { match: { params } } = props;

    useEffect(() => {
        props.getProduct({ product_id: params.id });

    }, [params]);

    const getGLBUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');
        if (link) {
            return link.model_url
        } else {
            return ''
        }
    }

    const getUSDZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'USDZ');
        if(link ) {
            return link.model_url
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (props.productReducer.isUpdated) {
            props.history.push('/store');
        }
    }, [props.productReducer.isUpdated])

    return (
        <div>
            <NavBar />
            <div style={{height: 80}}></div>
            <Row type="flex" justify="space-around">
                <Col id="centerAlign" span={24}>
                <CookieConsentBanner/>
                    <Tabs
                        tabBarStyle={{ 
                            display: 'flex', 
                            justifyContent: 'center',
                        }}
                        defaultActiveKey="1"
                    >
                        <TabPane tab="3D Model" key="1">
                            {(props.productReducer.product.download_links != undefined) ?
                                <ModelViewer src={getGLBUrl()} ios_src={getUSDZUrl()} />
                                :
                                ""
                            }
                        </TabPane>
                        <TabPane tab="Product Photography" key="2">
                            <Carousal images={props.productReducer.product['2d']} />
                        </TabPane>
                    </Tabs>
                    <Row type="flex" className="name-row-style margin-class">
                        <h3 className="product-name-h3">{props.productReducer.product.product_name}</h3>
                        {props.productReducer.product.price ? <h3 className="product-name-h3-id">{props.productReducer.product.price} </h3>: <h3 className="product-name-h3-id">Free</h3>}          
                    </Row>

                    <Row type="flex" className="category-row-style margin-class">
                        { props.productReducer.product.category ? <h3 className="product-detail-list" style={{marginRight:"40px"}}>Category: <span style={{fontWeight:"bold",color:"#333333"}}>
                            {props.productReducer.product.category}
                            </span>
                        </h3> : ""}
                        <h3 className="product-detail-list" style={{marginRight:"60px"}}>ID: <span style={{fontWeight:"bold",color:"#333333"}}>{params.id}</span></h3>
                        {props.productReducer.product.license ? <h3 className="product-detail-list">License Type: <span style={{fontWeight:"bold",color:"#276DD7"}}>{props.productReducer.product.license}</span></h3> : ""}
                    </Row>

                    {props.productReducer.product.brand && props.productReducer.product.external_links ? <Row type="flex" style={{ justifyContent: "flex-start" }} className="margin-class">
                        {props.productReducer.product.brand ? <h3 className="product-detail-list" style={{marginRight:"40px"}}>Model By: <span style={{fontWeight:"bold",color:"#333333"}}>{props.productReducer.product.brand}</span></h3> : ""}
                        {props.productReducer.product.external_links ? <h3 className="product-detail-list" style={{marginRight:"40px"}}>External Links: <span style={{fontWeight:"bold",color:"#333333"}}>{props.productReducer.product.external_links}</span></h3> : ""}
                    </Row> : ""}

                    <hr className="margin-class-hr"/>

                    <Row type="flex" style={{ justifyContent: "flex-start"}} className="margin-class">
                        {(props.productReducer.product.components) ? 
                        <div style={{paddingRight:"60px"}}>
                            <h3 className="product-detail-list" style={{textAlign:"left"}}>Components
                                <div style={{display:"flex",flexWrap:"wrap"}}>
                                    {props.productReducer.product.components.map((asset) => {
                                    return(
                                            <div className="bg-tag btm-10">
                                                <span style={{color:"#333333",fontSize:"16px"}}>
                                                    {asset}
                                                </span>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </h3>
                        </div> : ""}

                        {props.productReducer.product.height &&  props.productReducer.product.model_info.depth && props.productReducer.product.model_info.width ?
                        <div style={{display:"flex",paddingRight:"60px"}}>
                            <h3 className="product-detail-list" style={{textAlign:"left"}}>Height
                            <div className="bg-tag">
                                <span style={{color:"#333333",fontSize:"16px"}}>
                                {Math.round(props.productReducer.product.model_info.height)} inches
                                </span>
                            </div>
                            </h3>
                            <h3 className="product-detail-list" style={{textAlign:"left"}}>Width
                            <div className="bg-tag">
                                <span style={{color:"#333333",fontSize:"16px"}}>
                                {Math.round(props.productReducer.product.model_info.width)} inches
                                </span>
                            </div>
                            </h3>
                            <h3 className="product-detail-list" style={{textAlign:"left"}}>Depth
                            <div className="bg-tag">
                                <span style={{color:"#333333",fontSize:"16px"}}>
                                {Math.round(props.productReducer.product.model_info.depth)} inches
                                </span>
                            </div>
                            </h3>
                        </div> : ""}

                        {(props.productReducer.product.tags) ? 
                        
                            <h3 className="product-detail-list" style={{textAlign:"left"}}>Tags
                                <div style={{display:"flex",flexWrap:"wrap"}}>
                                    {props.productReducer.product.tags.map((asset) => {
                                    return(
                                        
                                            <div className="bg-tag btm-10">
                                                <span style={{color:"#333333",fontSize:"16px"}}>
                                                    {asset}
                                                </span>
                                            </div>
                                        
                                        )}
                                    )}
                                </div>
                            </h3>
                        : ""}

                    </Row>
                </Col>
            </Row>
            <div className='abs-btm'>
                <Footer />
            </div>  
        </div>
    );


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductAssets)
)
