import React from "react";
import "./index.scss";
import ModelViewer from "../sectionTwo/modelViewer";
import { ReactComponent as ARIcon } from "../../../ar.svg";
import TextConstants from "../../../TextConstants";

const CreateVirtualProducts = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};

const { title, list, icon_text1, icon_text2 } =
  TextConstants.CREATE_VIRTUAL_PRODUCTS;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block" style={{ width: "80%" }}>
      <div className="row justify-space-between" style={{ margin: "auto" }}>
        <div className="col-lg-6 col-12">
          <ModelViewer class="virtual-product" />
          <div className="helper d-lg-none d-block m-3 text-center">
            * {icon_text1} <ARIcon /> {icon_text2}
          </div>
        </div>

        <div className="col-lg-6 col-12" style={{ paddingLeft: 50 }}>
          <h3 className="font graphik-medium f-18 w-500 black-2b">{title}</h3>
          <ul className="font graphik-regular f-16 w-400 grey-light list-pd">
            {list.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="our-product-offering">
        <div className="col-12  justify-in-center direction-flex col">
          <h3 className="font graphik-medium f-16 w-500 black-2b">{title}</h3>
          <ul
            className="font graphik-regular f-14 w-400 grey-light list-pd"
            style={{ textAlign: "left" }}
          >
            {list.map((x) => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
        <div className="col-12" style={{ margin: "auto" }}>
          <ModelViewer class="virtual-product" />
          <div className="helper d-lg-none d-block m-3 text-center font graphik-regular f-14 w-400 black-2b">
            {icon_text1} <ARIcon /> {icon_text2}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateVirtualProducts;
