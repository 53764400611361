import React, { useState } from "react";
import { Col, Row, Form, Input, Button, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import * as ENVIRONMENT from "../../../env";

const formRef = React.createRef();

const FormSection = () => {
  const [buttonLoader, setButtonLoader] = useState(false);

  const onFinish = (values) => {
    console.log(values);
    setButtonLoader(true);
    axios
      .post(ENVIRONMENT.HIGH_POINT_CONTACT_IS, JSON.stringify(values))
      .then((res) => {
        formRef.current.resetFields();
        setButtonLoader(false);
        Modal.success({
          content:
            "Thanks for showing your interest. We will get in touch with you very soon.",
        });
      })
      .catch((error) => {
        console.log(error);
        setButtonLoader(false);
        message.error("Error in sending message.");
      });
  };

  return (
    <div style={{ backgroundColor: "#23222d", padding: 30 }}>
      <div
        style={{ textAlign: "center", padding: 28, backgroundColor: "#23222d" }}
      >
        <Row>
          <h5 style={{ color: "#ffffff", marginBottom: 28, fontSize: 22 }}>
            Contact Us to schedule a meeting
          </h5>
        </Row>
        <Form name="nest-messages" onFinish={onFinish} ref={formRef}>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <Form.Item
                name="first_name"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 14px)",
                  minWidth: 260,
                  marginBottom: "16px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                ]}
              >
                <Input
                  className="name-placeholder"
                  placeholder="First Name"
                  style={{
                    width: "calc(100% - 16px)",
                    borderRadius: 4,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 14px)",
                  minWidth: 260,
                  marginBottom: "16px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },
                ]}
              >
                <Input
                  className="name-placeholder"
                  placeholder="Last Name"
                  style={{
                    width: "calc(100% - 16px)",
                    borderRadius: 4,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="company"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 14px)",
                  minWidth: 260,
                  marginBottom: "16px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter Company!",
                  },
                ]}
              >
                <Input
                  className="name-placeholder"
                  placeholder="Company"
                  style={{
                    width: "calc(100% - 16px)",
                    borderRadius: 4,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={16}>
              <Form.Item
                name="phone"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 14px)",
                  minWidth: 260,
                  marginBottom: "16px",
                }}
              >
                <Input
                  className="name-placeholder"
                  placeholder="Phone"
                  style={{
                    width: "calc(100% - 16px)",
                    borderRadius: 4,
                  }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                style={{
                  display: "inline-block",
                  width: "calc(33% - 14px)",
                  minWidth: 260,
                  marginBottom: "16px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter email!",
                  },
                ]}
              >
                <Input
                  className="name-placeholder"
                  placeholder="Email"
                  style={{
                    width: "calc(100% - 16px)",
                    borderRadius: 4,
                  }}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                disabled={buttonLoader}
                style={{
                  backgroundColor: "rgba(194,163,116,1)",
                  border: "none",
                  height: "50px",
                  width: "calc(33% - 30px)",
                  minWidth: 244,
                  borderRadius: 4,
                  marginRight: 16,
                  marginBottom: 16,
                }}
              >
                <span style={{ color: "white", fontSize: "large" }}>
                  Submit
                  {buttonLoader ? (
                    <LoadingOutlined style={{ marginLeft: 4 }} />
                  ) : (
                    ""
                  )}
                </span>
              </Button>
            </Col>
            <Col span={4}></Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormSection;
