import React from 'react';

function NutShell() {
  return (
    <div className="nutshell-bg">

      <div className="container ">
      <div className="row plain-bg">
        <div className='col-12'>
          <div className=" p-sm-5 nutshell-container" >
            <h4 className='heading'>What is ALL3D in a nutshell?</h4>
            <p className="nutshell-p"> ALL3D provides services and solutions for you to create, approve, customize,
            collaborate, manage, distribute and monetize your 2D and 3D assets for valuable experiences that span
                  from ecommerce and advertising across any device.</p>
          </div>
        </div>

      </div>
        
      </div>

    </div>
  );
}


export default NutShell;
