import * as THREE from 'three';
import _ from "lodash" // Import the entire lodash library
import {DeviceOrientationControls} from './DeviceOrientationControls.js'
import * as Utilities from './Utilities';
import $ from 'jquery';
// polyfill
// Event.composedPath
(function(e, d, w) {
  if(!e.composedPath) {
    e.composedPath = function() {
      if (this.path) {
        return this.path;
      } 
    var target = this.target;

    this.path = [];
    while (target.parentNode !== null) {
      this.path.push(target);
      target = target.parentNode;
    }
    this.path.push(d, w);
    return this.path;
    }
  }
})(Event.prototype, document, window);


class VirtualShowroom {

	init(tour_name, container_name, loading_container,loading_image) {

		this.container_name = container_name;
		this.sceneContainer = document.getElementById( container_name );
		this.loadingContainer = document.getElementById( loading_container );
		this.hotspotTemplate = document.getElementById( tour_name );
		this.loadingImageContainer = document.getElementById( loading_image )
		this.sceneWidth = 0;
		this.sceneHeight = 0;
		this.aspect = 0.0;
		this.imageIndex = 0;
		this.scene = null;
		this.sceneRenderer = null;

		this.panoCam = null;
		this.panoSphere = null;
		this.panoMat = new THREE.MeshBasicMaterial();

		this.autoRotate = true;
		this.isUserInteracting = false;
		this.isCameraAnimating = false;
		this.isBlinking = false;
		this.isReady = false;

		this.onMouseDownMouseX = 0;
		this.onMouseDownMouseY = 0;
		this.defaultLon = 180;
		this.lon = 180;
		this.onMouseDownLon = 0;
		this.lat = 0;
		this.onMouseDownLat = 0;
		this.phi = 0;
		this.theta = 0;
		this.panoCamDirection = new THREE.Vector3();
		this.targetFOV = 10;

		this.loadingImageURL = null;
		this.panos = null;
		this.hotspotElements = [];
		this.currentPano = 0;

		this.loader = new THREE.TextureLoader();

		this.baseURL = "https://all3d-demos.s3.amazonaws.com/showroom-jsons/";

		this.currentWorldPoint = new THREE.Vector3();
		this.currentDirection = new THREE.Vector3();
		this.sensorControls = null;

		

	}

	constructor( tourInfo,fullScreen, tour_name, container_name, loading_container,loading_image,section = 'hero') {
		this.init(tour_name,container_name, loading_container,loading_image);
		this.section = section;
		if (this.fullscreenFlag === "" || this.fullscreenFlag === undefined) {
			this.fullscreenFlag = false;
		}
		else {
			this.fullscreenFlag = fullScreen;
		}
		this.sceneContainer.style.display = "none";
		this.hotspotTemplate.style.display = "none";
		this.loadingContainer.style.display = "none";

		this.updateScreenProps();

		this.scene = this.buildScene();
		this.sceneRenderer = this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
		this.sceneContainer.appendChild( this.sceneRenderer.domElement );

		this.panoCam = new THREE.PerspectiveCamera( 75, this.sceneWidth / this.sceneHeight, 1, 1100 );
		this.panoCam.target = new THREE.Vector3( 0, 0, 0 );

		var geometry = new THREE.SphereBufferGeometry( 500, 60, 40 );
		geometry.scale( - 1, 1, 1 ); // flips the sphere faces

		this.panoSphere = new THREE.Mesh( geometry, this.panoMat );
		this.scene.add( this.panoSphere );
		if (this.deviceType() == 'mobile') {
            if (this.isIphone()) {	
				let background_mask = document.getElementById("homepage");
				if (background_mask) {
					background_mask.style.filter = 'blur(5px)';
				}
				let permission_granted = document.getElementById("permission-yes");
				if (permission_granted) {
					if (Utilities.getCookie('gyro-permissions')) {
						$("#permission-yes").trigger("click");
						document.getElementById("permission").style.display = 'none';
						if (background_mask) {
							background_mask.style.filter = 'none';
						}
						this.sensorControls = new DeviceOrientationControls(this.panoCam);
						this.sensorControls.requestPermission();
					} else {
						permission_granted.onclick= () => {
							this.sensorControls = new DeviceOrientationControls(this.panoCam);
							this.sensorControls.requestPermission();
							document.getElementById("permission").style.display = 'none';
							if (background_mask) {
								background_mask.style.filter = 'none';
							}
							if (!Utilities.getCookie("gyro-permissions")) {
								Utilities.setCookie("gyro-permissions", true, 365);
							}
						}; 	
					}
					
				}	 
				
				let permission_denied = document.getElementById("permission-no");
				if (permission_denied) {
					permission_denied.onclick= () => {
						document.getElementById("permission").style.display = 'none';
						if (background_mask) {
							background_mask.style.filter = 'none';
						}
					}
				}
			}
			else {
				let permission = document.getElementById("permission");
				if (permission) {
					permission.style.display = 'none';
				}
				this.sensorControls = new DeviceOrientationControls(this.panoCam);
				this.sensorControls.connect();
			}
			
        } else {
			let permission = document.getElementById("permission");
			if (permission) {
				permission.style.display = 'none';
			}
		}

		this.setupEventListeners();
		let tourInformation = JSON.parse(tourInfo);


		let flag = false;
		let temp_panos = _.cloneDeep(tourInformation.panos);
		tourInformation.panos.map((pano,index) => {
			if (pano.title == "Living") {
				temp_panos[0] = _.cloneDeep(pano);
				flag = true;
			}
		});

		let new_index = 1;
		tourInformation.panos.map((pano,index) => {
			if (pano.title != "Living" && flag) {
				temp_panos[new_index] = _.cloneDeep(pano);
				new_index = new_index + 1;
			}
		});

		if (flag) {
			tourInformation.panos = temp_panos;
		}

		this.loadPanos( tourInformation );
		this.updateCamera();
		this.animate();

	}

	isIphone () {
        if( /iPhone/i.test(navigator.userAgent) ) {
            return true;
        }
        return false;
	}

	deviceType() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            return 'mobile'
        }
        return 'desktop';
    }

	buildScene() {

        const scene 		= new THREE.Scene();
        scene.background 	= new THREE.Color("#ffffff");
        return scene;

    }

    buildSceneRenderer( width, height ) {

        const renderer 				= new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
        renderer.outputEncoding		= THREE.LinearEncoding;
        renderer.gammaFactor 		= 2.2;
        renderer.setClearColor( 0x000000 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;

    }

    getParams ( url ) {
		let params = {};
		let parser = document.createElement('a');
		parser.href = url;
		let query = parser.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	}

    setupEventListeners() {

    	this.sceneContainer.addEventListener( 'mousedown', ( event ) => { this.onMouseDown( event ); }, false );
		this.sceneContainer.addEventListener( 'mousemove', ( event ) => { this.onMouseMove( event ); }, false );
		this.sceneContainer.addEventListener( 'mouseup', ( event ) => { this.onMouseUp( event ); }, false );
		// this.sceneContainer.addEventListener( 'wheel', ( event ) => { this.onWheel( event ); }, false );
		this.sceneContainer.addEventListener( 'keydown', ( event ) => { this.onKeyDown( event ); }, false );
		this.sceneContainer.addEventListener( 'touchstart', ( event ) => { this.onMouseDown( event ); }, false );
		this.sceneContainer.addEventListener( 'touchmove', ( event ) => { this.onMouseMove( event ); }, false );
		this.sceneContainer.addEventListener( 'touchend', ( event ) => { this.onMouseUp( event ); }, false );
		window.addEventListener( 'resize', () => { this.onWindowResize(); }, false );
		window.addEventListener( 'click', () => { this.untagHotspot(); }, false );
    }

    loadPanos( info ) {

    	if ( info.newScheme !=null && info.newScheme != undefined && info.newScheme == true ) {

    		var geometry = new THREE.SphereBufferGeometry( 500, 60, 40 );
			geometry.scale( 1, 1, -1 ); // flips the sphere faces
    		this.panoSphere.geometry = geometry;
    		this.panoSphere.rotation.y = Math.PI/2;
    		
			this.defaultLon = -90;
			this.lon = -90;
			
    		this.panoCam.target = new THREE.Vector3( 0,0,-1 );
    		this.updateCamera();

    	}

		if (info.panos) {
			this.loadingImageURL = "tour-bg.jpg";
			if (this.loadingImageContainer != null) {
				this.loadingImageContainer.src =	this.loadingImageURL;
			}
			
		}
		

    	this.loadingContainer.style.display = "block";
    	this.panos = info.panos;

		this.loadNextPano( 0 );
    }

    loadNextPano( index ) {

    	let scope = this;
		

    	if ( index < this.panos.length ) {
				this.path =  '/3dtour/' + this.panos[ index ].filename;
				if (this.section != 'hero') {
					this.path =  '/3dtour/productoffering/' + this.panos[ index ].filename;
				}
				this.loader.load(  this.path, ( texture ) => {

    			scope.panos[ index ].texture = texture;

    			for ( let hotspot of scope.panos[ index ].hotspots ) {

					hotspot.worldPoint = new THREE.Vector3( hotspot.position.x, hotspot.position.y, hotspot.position.z );

				}

				if ( !scope.isReady ) {

					scope.panoMat.map = scope.panos[ index ].texture;
					scope.panoMat.needsUpdate = true;
					scope.clearAllHotspots();
					scope.showHotSpots( scope.panos[ index ] );

					scope.currentPano = 0;
					scope.isReady = true;
					scope.loadingContainer.style.display = "none";
					scope.sceneContainer.style.display = "flex";
					scope.sceneContainer.style.justifyContent = "center";
					if (scope.panos[scope.currentPano].centerTarget) {
                        this.defaultLon = scope.panos[scope.currentPano].centerTarget;
                        this.lon = this.defaultLon;
						this.updateCamera();
                    }

				}

				else if ( scope.isReady && scope.currentPano == index && scope.loadingContainer.style.display == "block" ) {
					
					this.sceneContainer.style.display = "flex";
					this.sceneContainer.style.justifyContent = "center";

					this.loadingContainer.style.display = "none";
					scope.showPanoAtIndex( index );

				}

				scope.loadNextPano( index + 1 );

    		} );

    	}

    }

    getPanoIndexByTitle( title ) {

    	for ( let i = 0; i < this.panos.length; i++ ) {

    		if ( this.panos[i].title == title ) {

    			return i;

    		}

    	}

    }

    getHotspotIndexByTitle( title ) {

    	for ( let i = 0; i < this.panos[this.currentPano].hotspots.length; i++ ) {

    		if ( this.panos[this.currentPano].hotspots[i].title == title ) {

    			return i;

    		}

    	}

    }

    showPanoByTitle( title ) {

    	for ( let i = 0; i < this.panos.length; i++ ) {

    		if ( this.panos[i].title == title ) {

    			this.panoMat.map = this.panos[i].texture;
    			this.panoMat.needsUpdate = true;
    			this.currentPano = i;
    			this.clearAllHotspots();
    			this.showHotSpots( this.panos[i] );

    		}

    	}

    }

    showPanoAtIndex( index ) {

    	if ( this.panos[ index ].texture ==  undefined ) {

    		this.sceneContainer.style.display = "none";
    		this.loadingContainer.style.display = "block";
    		this.currentPano = index;
    		return;

    	}

    	this.panoMat.map = this.panos[ index ].texture;
    	this.panoMat.needsUpdate = true;
    	this.currentPano = index;
    	this.showHotSpots( this.panos[index] );

    }

    clearAllHotspots() {

    	if ( this.hotspotElements.length > 0 ) {

    		for ( let e of this.hotspotElements ) {

    			this.sceneContainer.removeChild( e );

    		}

    		this.hotspotElements = [];
    	}

    }

    showHotSpots( pano ) {
    	for ( let hotspot of pano.hotspots ) {

    		let hotSpotClone = this.hotspotTemplate.cloneNode(true);
    		hotSpotClone.children[1].innerText = hotspot.title;
					// if (hotspot.title != 'Living') {
				
			// 	this.defaultLon = -90;
			// 	this.lon = -90;
			// } else {
			// 	this.defaultLon = -270;
			// 	this.lon = -270;
			// }
			hotSpotClone.style.display = 'block';
			hotSpotClone.addEventListener( 'mouseover', ( event ) => { this.tagHotspot( event ); }, false );
        	hotSpotClone.addEventListener( 'click', ( event ) => { this.onHotspotClicked( event ); }, false );
        	this.sceneContainer.appendChild( hotSpotClone );
        	this.hotspotElements.push( hotSpotClone );

    	}

    }

    worldToNDC ( worldPoint , camera ) {
        let NDCPoint = worldPoint.clone();
        camera.updateMatrixWorld();
        NDCPoint.project( camera );
        return NDCPoint;
    }

    getScreenPoint ( NDCPoint ) {
        let NDCToPixelX = ((NDCPoint.x + 1) / 2) * this.sceneWidth;
        let NDCToPixelY = (1 - (NDCPoint.y + 1) / 2) * this.sceneHeight;
        let screenPoint = new THREE.Vector2( NDCToPixelX, NDCToPixelY );
        return screenPoint;
    }

    onMouseDown( event ) {

    	if ( event.type != 'touchstart' ) {
    		event.preventDefault();
    	}

		this.isUserInteracting = true;
		this.autoRotate = false;

		var clientX = event.clientX || event.touches[ 0 ].clientX;
		var clientY = event.clientY || event.touches[ 0 ].clientY;

		this.onMouseDownMouseX = clientX;
		this.onMouseDownMouseY = clientY;

		this.onMouseDownLon = this.lon;
		this.onMouseDownLat = this.lat;

    }

    onMouseMove( event ) {

    	event.preventDefault();

		if ( this.isUserInteracting === true ) {

			var clientX = event.clientX || event.touches[ 0 ].clientX;
			var clientY = event.clientY || event.touches[ 0 ].clientY;

			this.lon = ( this.onMouseDownMouseX - clientX ) * 0.1 + this.onMouseDownLon;
			this.lat = ( clientY - this.onMouseDownMouseY ) * 0.1 + this.onMouseDownLat;

			this.updateCamera();

		}

    }

    onMouseUp( event ) {

    	if ( event.type != 'touchend' ) {
    		event.preventDefault();
    	}

		this.isUserInteracting = false;
		this.autoRotate = true;

    }

    onWheel( event ) {

    	event.preventDefault();

    	var fov = this.panoCam.fov + ( -event.deltaY * 0.05 );
		this.panoCam.fov = THREE.MathUtils.clamp( fov, 10, 75 );
		this.panoCam.updateProjectionMatrix();

    }

    onDragOver( event ) {

    	event.preventDefault();
		event.dataTransfer.dropEffect = 'copy';

    }

    onDragEnter( event ) {

		document.body.style.opacity = 0.5;

    }

    onDragLeave( event ) {

    	document.body.style.opacity = 1;

    }

    onDrop( event ) {

    	event.preventDefault();

    	let scope = this;

		var reader = new FileReader();
		reader.addEventListener( 'load', function ( event ) {

			scope.panoMat.map.image.src = event.target.result;
			scope.panoMat.map.needsUpdate = true;

		}, false );
		reader.readAsDataURL( event.dataTransfer.files[ 0 ] );

		document.body.style.opacity = 1;

    }

    onKeyDown( event ) {

    	switch ( event.keyCode ) {

			case 32: /*SPACEBAR*/
			// direction = 1;
			// autoRotate = ( ! autoRotate );
			break;

			case 87: // W
			// loadNextPano();
			break;

			case 83: // S
			// loadPreviousPano();
			break;
		}

    }

    onHotspotClicked( event ) {

		event.preventDefault();

		this.autoRotate = false;

		if ( this.isCameraAnimating || this.isBlinking ) {
			return;
		}

		this.clearAllHotspots()

		let path = event.path || (event.composedPath && event.composedPath());

		let hotspotTitle = path[1].children[1].innerText;
		let hotspotIndex = this.getHotspotIndexByTitle( hotspotTitle );

		this.panoCam.lookAt( this.panos[this.currentPano].hotspots[hotspotIndex].worldPoint );
		this.currentPano = this.getPanoIndexByTitle( hotspotTitle );

		this.targetFOV = 10;
		this.isCameraAnimating = true;
	}

	toggleFull (flag) {
		if (flag) {
			this.fullscreenFlag = flag;
		}
		this.updateScreenProps();
		this.onWindowResize();
		this.update();
	}

	
	tagHotspot ( event ) {
		event.preventDefault();

		let path = event.path || (event.composedPath && event.composedPath());

		this.untagHotspot();
		path[1].children[1].style.visibility = "visible";
		path[1].children[1].style.opacity = "1";
		path[1].children[1].style.transition = "all 2s";
		this.currentTaggedHotspot = path[1];

	}

    updateScreenProps() {
			this.sceneWidth = window.innerWidth - 19;
			this.sceneHeight = window.innerHeight;
		if (this.section == 'web') {
			this.sceneWidth = window.innerWidth  - 330;
			this.sceneHeight = window.innerHeight - 165;
		} else if (this.section == 'mobile') {
			this.sceneWidth = window.innerWidth - 30//- 143;
			this.sceneHeight = window.innerHeight - 250;
		} else if (this.section == 'hero' && (this.sceneWidth + 19) < 992) {
			this.sceneWidth = window.innerWidth;
			this.sceneHeight = window.innerHeight - 250;
		} 
		
		this.aspect = this.sceneWidth / this.sceneHeight;

    }

    updateCamera() {
		if (this.sensorControls && this.sensorControls.enabled) {
			this.sensorControls.update();
		}
		else {
			this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
			this.phi = THREE.MathUtils.degToRad( 90 - this.lat );
			this.theta = THREE.MathUtils.degToRad( this.lon );

			this.panoCam.target.x = 500 * Math.sin( this.phi ) * Math.cos( this.theta );
			this.panoCam.target.y = 500 * Math.cos( this.phi );
			this.panoCam.target.z = 500 * Math.sin( this.phi ) * Math.sin( this.theta );

			this.panoCam.lookAt( this.panoCam.target );
		}
    }

    updateHotspotPositions() {

    	if ( this.hotspotElements.length > 0 ) {

    		this.panoCam.getWorldDirection( this.panoCamDirection );

    		for ( let i = 0; i < this.hotspotElements.length; i++ ) {

    			this.currentWorldPoint.copy( this.panos[this.currentPano].hotspots[i].worldPoint );
    			this.currentDirection.copy( this.currentWorldPoint.sub( this.panoCam.position  ) );
		    	let dotPrdouct = this.currentDirection.dot( this.panoCamDirection );
		    	let screenPoint = this.getScreenPoint( this.worldToNDC( this.panos[this.currentPano].hotspots[i].worldPoint, this.panoCam ) );
		    	if ( screenPoint.x < this.sceneWidth && screenPoint.y < this.sceneHeight && dotPrdouct > 0 ) {	    			
	    			this.hotspotElements[i].style.left = ( screenPoint.x - 15 ) + 'px';
	    			this.hotspotElements[i].style.top = ( screenPoint.y - 15 ) + 'px';
	    			this.hotspotElements[i].style.display = 'block';
    			}

    			else {

    				this.hotspotElements[i].style.display = 'none';

    			}

    		}

    	}


	}
	
	untagHotspot () {
		// untag hotspot
		if (this.currentTaggedHotspot != null) {
			this.currentTaggedHotspot.children[1].style.visibility = "hidden";
			this.currentTaggedHotspot.children[1].style.opacity = "0";
			this.currentTaggedHotspot.children[1].style.transition = "opacity 1s";
		}
	}

    onWindowResize() {

    	this.updateScreenProps();

        this.sceneRenderer.domElement.style.margin = "0 auto"
        this.sceneRenderer.domElement.style.display = "flex";
        this.sceneRenderer.domElement.style.flexDirection = "row";
        this.sceneRenderer.domElement.style.justifyContent = "center";
        this.sceneRenderer.domElement.style.alignItems = "center";
		this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );

		this.panoCam.aspect = this.aspect;
		this.panoCam.updateProjectionMatrix();

	}

	render() {

		this.sceneRenderer.clear();
        this.sceneRenderer.render( this.scene, this.panoCam );

	}

	update() {

		if ( this.isCameraAnimating ) {

			this.panoCam.fov = THREE.MathUtils.lerp( this.panoCam.fov, this.targetFOV, 0.08 );
			this.panoCam.updateProjectionMatrix();

			if( Math.abs( this.targetFOV - this.panoCam.fov ) < 5 ) {
				this.isCameraAnimating = false;
				document.body.style.opacity = 0.0;
				this.showPanoAtIndex( this.currentPano );
				this.panoCam.fov = 75;
				this.panoCam.updateProjectionMatrix();
			
				this.lat = 0;
				if (this.panos[this.currentPano].centerTarget) {
                    this.defaultLon = this.panos[this.currentPano].centerTarget
                }
				this.lon = this.defaultLon;

				this.isBlinking = true;
				this.updateCamera();
			}
			
		}

		if ( this.isBlinking ) {

			document.body.style.opacity = THREE.MathUtils.lerp( document.body.style.opacity, 1.0, 0.05 );

			if ( document.body.style.opacity > 0.97 ) {
				document.body.style.opacity = 1.0;
				this.isBlinking = false;
				this.autoRotate = true;
			}
		}

		this.updateScreenProps();

		if ( this.autoRotate ) {
			this.lon += 0.03;
			this.updateCamera();
		}

		if ( !this.isCameraAnimating && !this.isBlinking ) {
			this.updateHotspotPositions();
		}

	}

	animate() {
		requestAnimationFrame( ()=>{ 
			this.animate(); 
		} );
        this.render();
        this.update();
	}

}

export default VirtualShowroom;