import React, { useEffect } from "react";
import "./index.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Card } from "antd";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

const WhoCanUseUs = () => {
  // useEffect(() => {
  //   var btnText = document.getElementById("read");
  //   console.log(btnText)
  //   if (btnText != null) {
  //     btnText.addEventListener("click", readAhead);
  //   }
  //   return () => {
  //     if (btnText != null) {
  //       btnText.removeEventListener("click", readAhead);
  //     }
  //   }
  // }, []);

  // const readAhead = () => {
  //   var moreText = document.getElementById("read_more");
  //   var btnText = document.getElementById("read");

  //   if (moreText.style.display === "inline") {
  //     btnText.innerHTML = "... Read More";
  //     moreText.style.display = "none";
  //   } else {
  //     btnText.innerHTML = " Show Less";
  //     moreText.style.display = "inline";
  //   }
  // }

  return (
    <div className="container-fluid who-can-use-us-container" id="#whoCanUseUS">
      <div className="who-can-use-us">
        <div className="row justify-content-md-center">
          <WebVersion />
          <MobileVersion />
        </div>
      </div>
    </div>
  );
};
const { title, text, btn_text } = TextConstants.WHO_CAN_USE_US;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block" id="web">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto" }}
      >
        <h1
          className="font lora f-32 ws-6 w-400 black-2b justify-in-center justify-in-center"
          style={{ textAlign: "center", marginBottom: 12 }}
        >
          {title}
        </h1>
        <p
          className="font graphik-regular f-18 w-400 black-2b justify-in-center"
          style={{
            textAlign: "center",
            margin: "auto",
            width: "100%",
            marginBottom: 40,
          }}
        >
          {text}
        </p>
      </div>
      <div className="row mx-auto my-auto">
        {FileConstants.who_can_use_us.map((who) => (
          <div className="col-4" id="textSection">
            <Card className="user-card">
              <img
                className="user-type"
                src={who.icon}
                style={{ marginBottom: 16 }}
                alt="who icon"
              />
              <h5 className="font graphik-medium f-16 w-400 black-2b">
                {who.user}
              </h5>
              <h5 className="font graphik-regular f-16 w-400 grey-light hyphen-justify">
                {who.text}
              </h5>
              {who.link ? (
                <a
                  href={who.link}
                  target="_blank"
                  className="font graphik-regular f-14 w-500 brown"
                >
                  {btn_text} <ArrowRightOutlined />
                </a>
              ) : (
                ""
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none" id="mobile">
      <div className="col-lg-10 col-12" id="textSection">
        <h1
          className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
          style={{ marginBottom: 10 }}
        >
          {title}
        </h1>
        <p
          className="font graphik-regular f-14 w-400 black-2b justify-in-center"
          style={{ textAlign: "center", marginBottom: 20 }}
        >
          {text}
        </p>
      </div>
      <div className="row mx-auto my-auto">
        {FileConstants.who_can_use_us.map((who) => (
          <div className="col-12" id="textSection" style={{ marginTop: 12 }}>
            <Card className="user-card">
              <img
                className="user-type"
                src={who.icon}
                style={{ marginBottom: 16 }}
                alt="who icon"
              />
              <h5 className="font graphik-medium f-16 w-500 black-2b">
                {who.user}
              </h5>
              <h5 className="font graphik-regular f-14 w-400 grey-light">
                {who.text}
              </h5>
              {who.link ? (
                <a
                  href={who.link}
                  target="_blank"
                  className="font graphik-regular f-14 w-600 brown"
                >
                  {btn_text} <ArrowRightOutlined />
                </a>
              ) : (
                ""
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};
export default WhoCanUseUs;
