import React from "react";
import { useEffect, useState } from "react";
import $ from "jquery";
import FileConstants from "../../../FileConstants";
import "./index.scss";
import { Carousel } from "react-responsive-carousel";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import ModelViewer from "../sectionTwo/modelViewer";
import { ReactComponent as ARIcon } from "../../../ar.svg";
import { Animated } from "react-animated-css";
import LazyLoad from "react-lazyload";
import { Card } from "antd";
import * as ENVIRONMENT from "../../../env";
import ThreeSixtyViewer from "../../../ThreeSixtyViewer";
import CreateVirtualProducts from "./CreateVirtualProducts";
import GenerateProductImagery from "./GenerateProductImagery";
import CreateProductVariants from "./CreateProductVariants";
import CreateSpaces from "./CreateSpaces";
import ALL3DStore from "./ALL3DStore";
import CreateBrandPage from "./CreateBrandPage";
import CreateLifestyleImagery from "./CreateLifestyleImagery";
import RealtimeConfigurator from "./RealtimeConfigurator";
import Create3DTours from "./Create3DTours";
import GenerateSocialMediaContent from "./GenerateSocialMediaContent";
import ScanningApp from "./3DScanningApp";
import TextConstants from "../../../TextConstants";

const OurProductOffering = () => {
  return (
    <div className="container-fluid our-product-offering-container">
      <div className="row justify-content-md-center">
        <HeadingWeb />
        <HeadingMobile />
        <LazyLoad scroll={true}>
          <CreateVirtualProducts />
        </LazyLoad>
        <GenerateProductImagery />
        <CreateProductVariants />
        <CreateSpaces />
        <LazyLoad scroll={true}>
          <ALL3DStore />
        </LazyLoad>
        <LazyLoad scroll={true}>
          <CreateBrandPage />
        </LazyLoad>
        <LazyLoad scroll={true}>
          <CreateLifestyleImagery />
        </LazyLoad>
        <RealtimeConfigurator />
        <LazyLoad scroll={true}>
          <Create3DTours />
        </LazyLoad>
        <LazyLoad scroll={true}>
          <GenerateSocialMediaContent />
        </LazyLoad>
        <LazyLoad scroll={true}>
          <ScanningApp />
        </LazyLoad>
      </div>
    </div>
  );
};

const { title } = TextConstants.OUR_PRODUCT_OFFERING;

const HeadingWeb = () => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="col-lg-12 col-12 our-product-offering"
        id="textSection"
        style={{ width: "100vh" }}
      >
        <h2
          className="font lora f-32 ws-6 w-400 black-2b justify-in-center"
          style={{ marginBottom: 40 }}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

const HeadingMobile = () => {
  return (
    <div className="d-lg-none" style={{ width: "100%", textAlign: "center" }}>
      <div
        className="col-12 our-product-offering"
        id="textSection"
        style={{ padding: 0, margin: 0 }}
      >
        <h2
          className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
          style={{ marginBottom: 24 }}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

export default OurProductOffering;
