import NavBar from "../landingPage/header/navbar";
import React, {useEffect, useState} from "react";
import Footer from "../landingPage/footer";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import { Helmet } from "react-helmet";
import "./index.scss";

const Integrations = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.INTEGRATIONS.title}</title>
        <link rel="canonical" href="https://all3d.ai/integrations" />
      </Helmet>
      <React.Fragment>
        <NavBar />
        <div className="container-fluid integrations-container">
          <div className="integrations">
            <WebVersion />
            <MobileVersion />
          </div>
        </div>
        <Footer />
      </React.Fragment>
    </div>
  );
};

const { cardTitle, cardSubText, buttonText, title, subText } =
  TextConstants.INTEGRATIONS;

const IntegrationCard = () => {
  const [source,setSource] = useState(null)

  useEffect(()=> {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source')
    if (source) {
      setSource(source)
    }
  },[])

  const handleCardClick = () => {
    if (source) {
      window.location.href = `/amazon?source=${source}`
    } else {
      window.location.href = '/amazon'
    }
  }

  return (
    <div className="integration-card">
      <div>
        <img src="/amazon-logo.svg" className="logo-style mt-16 "></img>
        <span className="font graphik-regular f-24 w-400 ws-6 black-2b mt-16 ">
          {cardTitle}
        </span>
        <p className="font graphik-regular f-16 w-400 black-2b mt-16">
          {cardSubText}
        </p>
      </div>
      <button
        onClick={handleCardClick}
        type="button"
        className="btn connect-btn font graphik-regular f-16 w-500 white"
      >
        {buttonText}
        <img className="ml-12" src="/landingPage/right-arrow.png" alt="#" />
      </button>
    </div>
  );
};

const IntegrationCardShopify = () => {
  return (
    <div className="integration-card">
      <div>
        <img src="/shopify-logo.svg" style={{marginTop:"-10px"}} className="logo-style mt-16 "></img>
        <span className="font graphik-regular f-24 w-400 ws-6 black-2b mt-16 ">
          Shopify
        </span>
        <p className="font graphik-regular f-16 w-400 black-2b mt-16">
          Manage your Shopify store content though ALL3D
        </p>
      </div>
      <button
        disabled
        onClick={() => {
          window.location.href = "/amazon";
        }}
        type="button"
        className="btn connect-btn font graphik-regular f-16 w-500 white"
      >
        Coming Soon
      </button>
    </div>
  );
};

const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h1 className="font lora f-32 w-400 ws-6 black-2b justify-in-center heading-background">
          {title}
        </h1>
        <p className="font graphik-regular f-20 w-400 black-2b mt-16 justify-in-center">
          {subText}
        </p>
      </div>
      <div className="cards-container">
        <IntegrationCard />
        <IntegrationCardShopify />
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h1 className="font lora f-32 w-400 ws-6 black-2b justify-in-center heading-background">
          {title}
        </h1>
        <p className="font graphik-regular f-16 w-400 black-2b mt-16 justify-in-center integration-detail-text">
          {subText}
        </p>
      </div>
      <div className="cards-container">
        <IntegrationCard />
        <IntegrationCardShopify />
      </div>
    </div>
  );
};

export default Integrations;
