import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VisibilitySensor from 'react-visibility-sensor';
import './index.scss';

class Carousal extends React.Component {
  constructor(props) {
    super(props)
    this.imageLabels = ["Lifestyle", "Product Perspective 1", "Product Perspective 2", "Dimensional Image", "Product Perspective 3", "Product Perspective 4", "Product Perspective 5"]
    this.state = {
      curImage: 0,
      curImage2: 0,
      autoPlay: false,
      isVisible: false
    }
    this.timer = null
  }
  updateLabel = (e, elm) => {
    this.setState({
      curImage: e
    })
  }

  updateLabel2 = (e, elm) => {
    this.setState({
      curImage2: e
    })
  }

  componentWillUnmount() {
    this.clearAuto()
  }

  clearAuto = () => {
    clearInterval(this.timer);
  }

  onChangeVisibility = (isVisible) => {
    if (isVisible == false) {
      this.setState({
        autoPlay: false,
        isVisible: false
      })
    } else {
      this.setState({
        autoPlay: true,
        isVisible: true,
        curImage: 0,
      }, () => {
        this.clearAuto()
        this.timer = setInterval(() => {
          let index = this.state.curImage + 1
          if (index > this.imageLabels.length) {
            index = 0
          }
          this.setState({ curImage: index })
        }, 3000)
      })
    }
  }
  render() {
    return (
      <div className='container carousal-container'>
          <div className='row no-gutters justify-content-center'>
            <div className='col-lg-9 col-md-12'>
              <VisibilitySensor  onChange={this.onChangeVisibility}>
                {

                  <Carousel centerMode centerSlidePercentage={80} onClickThumb={this.clearAuto} infiniteLoop showIndicators={true} showThumbs={false} selectedItem={this.state.curImage} showStatus={false} onChange={this.updateLabel}>
                    {(this.props.images || []).map((u, index) => {
                        return <div>
                            <img src={u} height={'100%'} alt="Product Image"/>
                        </div>
                    })}
                  </Carousel>

                }

              </VisibilitySensor>

            </div>
          </div>
      </div>
    );
  }

}


export default Carousal;
