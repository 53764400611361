import {
    PRODUCT_SUCCESS,
    PRODUCT_FAILURE,
    RENDER_PRODUCT,
    LIST_STORE_PRODUCT,
  } from '../actions';
  
  const initialState = {
    product: {
      required_assets: []
    },
    products: [],
    scannedProducts: [],
    isLoading: false,
    isCreated: false,
    isUpdated: false,
    error: null,
  };
  
  export default function productReducer(state = initialState, action) {
    switch (action.type) {
      case LIST_STORE_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
        };
      case RENDER_PRODUCT:
        return {
          product: { ...action.payload },
          products: state.products,
          scannedProducts: state.scannedProducts,
          isLoading: true,
          isCreated: false,
          isUpdated: false,
          error: null,
      };
      case PRODUCT_SUCCESS:
          return {
              product: { ...action.payload, ...state.product },
              products: action.payload.products || state.products,
              scannedProducts: action.payload.scannedProducts || state.scannedProducts,
              isLoading: false,
              isCreated: action.payload.isCreated,
              isUpdated: action.payload.isUpdated,
              error: null,
          };
      case PRODUCT_FAILURE:
          return {
              product: state.product,
              products: state.products,
              scannedProducts: state.scannedProducts,
              isLoading: false,
              isCreated: false,
              isUpdated: false,
              error: action.payload.message,
          };
      default:
          return state;
    }
  }
  