import React from "react";
import "./Termsandconditions.scss";
import {  Layout } from "antd";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";

const downloadPDF = () => {
    window.open("https://d48f7equ64qjl.cloudfront.net/static_files/All3D-TermsandConditions.pdf", "_blank").focus();;
}

const Termsandconditions = () => {
    return (
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{MetaTagContents.TERMS_AND_CONDITIONS.title}</title>
            <link rel="canonical" href="https://all3d.ai/termsandconditions" />
            <meta name={MetaTagContents.TERMS_AND_CONDITIONS.title} />
          </Helmet>
    
          <NavBar />
    
          <Layout>
            <WebVersion />
            <MobileVersion />
          </Layout>
          {/* <Footer /> */}
        </React.Fragment>
      );
};

const WebVersion = () => {
    return (
        <div className="d-none d-xl-block">
        <div className="terms-container">
          <div className="d-flex justify-content-between">
            <span className="font lora f-32 ws-6 w-400 black-2b justify-in-center pb-6">
                Terms and Conditions
            </span>
            <button onClick={downloadPDF} type="button" className="btn get-started-btn-blue font graphik-regular f-14 w-500 white download-pdf-btn">
                Print
            </button>
          </div>
        <iframe
          src="/termsandconditions.html"
          className="terms-document"
          title="Terms and Conditions"
        ></iframe>
        </div>
        </div>
      );
}

const MobileVersion = () => {
    return (
        <div className="d-xl-none">
        <div className="terms-container">
          <div className="d-flex justify-content-around w-100 pb-6">
            <span className="font lora f-20 ws-6 w-400 black-2b justify-in-center">
                Terms and Conditions
            </span>
            <button onClick={downloadPDF} type="button" className="btn get-started-btn-blue font graphik-regular f-14 w-500 white ">
                Print
            </button>
          </div>
        <iframe
          src="/termsandconditions.html"
          className="terms-document-mobile"
          title="Terms and Conditions"
        ></iframe>
        </div>
        </div>
      );
}

export default Termsandconditions;